import React, { useRef, useEffect, useMemo } from 'react';
import { useState } from 'react';

interface OnboardingSidebarProps {
  currentStep: number;
  isNewBusiness: boolean;
}

const DEFAULT_STEPS = [
  'Base setup',
  'Platforms Setup',
  'APIs Setup',
  'Configuration',
];
const NEW_BUSINESS_STEPS = ['Base setup', 'Configuration'];

const OnboardingSidebar: React.FC<OnboardingSidebarProps> = ({
  currentStep,
  isNewBusiness,
}) => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const steps = useMemo(
    () => (isNewBusiness ? NEW_BUSINESS_STEPS : DEFAULT_STEPS),
    [isNewBusiness]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  return (
    <div className="fixed top-0 right-0 h-full w-[280px] bg-secondarygray text-light flex flex-col justify-between rounded-bl-3xl">
      {showModal && (
        <section className="text-black fixed top-0 left-0 z-40 w-full h-screen bg-black bg-opacity-50 flex justify-center items-center">
          <div
            ref={modalRef}
            className="bg-white w-[38rem] h-fit md:h-[14rem] flex flex-col gap-8 justify-between shadow-sm rounded px-6 py-4"
          >
            <div className="flex flex-col items-center gap-4">
              <div className="text-lg font-semibold">
                Depending on your region contact the appropriate support:
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <div className="font-semibold">US -</div>
                  <div className="underline">
                    onesearch_us@publicismedia.com
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="font-semibold">EMEA -</div>
                  <div className="underline">onesearch_emea@performics.com</div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="font-semibold">Global -</div>
                  <div className="underline">
                    onesearch_global@performics.com
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center font-medium">
              <div
                onClick={() => setShowModal(false)}
                className="w-1/4 bg-black text-white rounded-full flex justify-center items-center py-1 sm:py-1.5 cursor-pointer select-none"
              >
                CLOSE
              </div>
            </div>
          </div>
        </section>
      )}
      <div className="w-full flex items-center justify-between p-0 m-0 bg-transparent relative">
        <div className="relative w-full mr-5">
          <img
            src={`${process.env.PUBLIC_URL}/static/img/Logo.svg`}
            alt="Logo"
            className="absolute top-[17px] right-[20px] z-[100] w-[123px] h-[20px] flex-shrink-0"
          />
          <div className="absolute top-0 right-[-20px] w-[280px] mb-[-20px] z-[1]">
            <img
              src={`${process.env.PUBLIC_URL}/static/img/Exclude_new.svg`}
              alt="Vector"
              className="w-full h-full scale-x-[-1]"
            />
          </div>
        </div>
      </div>
      <ul className="mt-[91px] px-6 flex-1 font-medium relative">
        <p className="text-light text-2xl font-bold">Onboarding</p>
        {steps.map((step, index) => (
          <li
            key={index}
            className="relative py-10 flex items-center whitespace-nowrap"
          >
            {index !== steps.length - 1 && (
              <div
                className={`absolute left-[7.5px] top-[60px] -bottom-11 w-[1px] ${
                  index + 1 < currentStep ? 'bg-light' : 'bg-success'
                }`}
              ></div>
            )}
            <div className="flex items-center">
              <div
                className={`h-4 w-4 rounded-full border-2 flex-shrink-0 ${
                  index + 1 <= currentStep
                    ? 'bg-light border-light'
                    : 'bg-success border-success'
                }`}
              ></div>
              <span
                className={`ml-4 ${
                  index + 1 <= currentStep
                    ? 'text-light font-medium'
                    : 'text-success  font-medium'
                }`}
              >
                {step}
              </span>
              {index + 1 < currentStep && (
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/Checkmark.svg`}
                  alt="checkmark"
                  className="ml-2"
                />
              )}
              {index + 1 === currentStep && (
                <span className="ml-2 bg-white text-primarygray px-2 rounded-[100px] text-sm font-semibold">
                  {isNewBusiness && currentStep > 1 ? '2' : currentStep} out of{' '}
                  {steps.length}
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-col justify-center items-center gap-4 px-4 mb-6 text-sm text-light">
        <p>
          Need help?{' '}
          <span
            className="underline cursor-pointer"
            onClick={() => setShowModal(true)}
          >
            Connect with support
          </span>
        </p>
      </div>
    </div>
  );
};

export default OnboardingSidebar;
