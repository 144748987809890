export const formatOrganicAOData = <T extends object>(data: T) => {
  const output: { [key: string]: unknown } = {};

  for (const [key, value] of Object.entries(data)) {
    const formattedDate = key.split('(')[1].split(' ')[0];

    output[formattedDate] = value;
  }

  return output as T;
};
