interface TableProps<T> {
  data: T[];
  columns: (keyof T)[];
  onEdit?: (item: T) => void;
  onDelete?: (id: number) => void;
  onAdd?: () => void;
}

function formatHeader(header: string): string {
  let formatted = header.replace(/_/g, ' ');
  formatted = formatted.replace(/([A-Z])/g, ' $1');
  return formatted.toUpperCase();
}

function Table<T extends { geo_id?: number; group_id?: number }>({
  data,
  columns,
  onEdit,
  onDelete,
  onAdd,
}: TableProps<T>) {
  return (
    <div className="overflow-auto max-h-96">
      <table className="min-w-full bg-light table-auto">
        <thead className="text-primarygray text-xs font-normal">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 text-left">
                <div className="flex items-center">
                  {formatHeader(column as string)}
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                    alt="sort-icon"
                    className="ml-2"
                  />
                </div>
              </th>
            ))}
            <th className="py-2 px-2 text-right">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="text-primarygray text-xs font-normal rounded-md">
          {data.map((item, index) => (
            <tr
              key={item.geo_id || item.group_id}
              className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}
            >
              {columns.map((column) => (
                <td key={column as string} className="py-2 px-4">
                  {String(item[column])}
                </td>
              ))}
              <td className="py-2 px-2 flex justify-end">
                {onEdit && (
                  <button onClick={() => onEdit(item)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                      alt="edit"
                      className="h-4 w-4 mr-2"
                    />
                  </button>
                )}
                {onDelete && (
                  <button
                    onClick={() => onDelete(item.geo_id || item.group_id!)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/delete_option.svg`}
                      alt="delete"
                      className="h-4 w-4"
                    />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="border border-thirdgray rounded-[20px] px-1 mt-2 w-[68px]">
        <button
          className="text-sm text-thirdgray font-bold flex items-center"
          onClick={onAdd}
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
            alt="plus_icon"
            className="w-[18px] h-[18px] mr-1"
          />
          ADD
        </button>
      </div>
    </div>
  );
}

export default Table;
