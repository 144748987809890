import 'chart.js/auto';
import { useState } from 'react';

// COMPONENTS
import { Line } from 'react-chartjs-2';
import CustomLegend from './CustomLegend';
import ChartContainer from './ChartContainer';

// TYPES
import { ChartOptions } from 'chart.js';
import { formatOrganicAOData } from 'utils';

interface OrganicPaidImpProps {
  data: Record<
    string,
    {
      organic_impressions: number;
      paid_impressions: number;
    }
  >;
}

function OrganicPaidImp({ data }: OrganicPaidImpProps) {
  const formattedData = formatOrganicAOData(data);

  const labels = Object.keys(formattedData).sort();

  const organicImpressions: number[] = [];
  const paidImpressions: number[] = [];

  for (const label of labels) {
    if (formattedData[label]) {
      organicImpressions.push(formattedData[label].organic_impressions);
      paidImpressions.push(formattedData[label].paid_impressions);
    }
  }

  const sumArrays = (arr1: number[], arr2: number[]): number[] => {
    if (arr1.length !== arr2.length) {
      throw new Error('Arrays must have the same length');
    }
    return arr1.map((num, index) => num + arr2[index]);
  };
  const sumArray: number[] = sumArrays(organicImpressions, paidImpressions);

  const [visibility, setVisibility] = useState<Record<string, boolean>>({
    Total: true,
    Organic: true,
    Paid: true,
  });

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const revenueOverviewData = {
    labels: labels,
    datasets: [
      {
        label: 'Total',
        data: sumArray,
        borderColor: '#222222',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#222222',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility.Total,
      },
      {
        label: 'Organic',
        data: organicImpressions,
        borderColor: 'rgba(50, 215, 111, 0.80)',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: 'rgba(50, 215, 111, 0.80)',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility.Organic,
      },
      {
        label: 'Paid',
        data: paidImpressions,
        borderColor: 'rgba(3, 197, 255, 0.80)',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: 'rgba(3, 197, 255, 0.80)',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility.Paid,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Week',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Impressions',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: 5000,
          callback: function (value) {
            const numericValue = Number(value);
            return numericValue >= 1000
              ? numericValue / 1000 + 'K'
              : numericValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  // JSX
  return (
    <ChartContainer
      header="Organic & Paid Impressions"
      description="Understand evolution of Organic Search and Paid Search impressions"
      downloadParams={{
        filename: 'autoOptimizeOrganicPaidImpressions',
        headers: ['date', 'total', 'organic', 'paid'],
        labels: revenueOverviewData.labels,
        columns: revenueOverviewData.datasets.map((item) => item.data),
      }}
    >
      <div className="flex">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%] h-[295px]">
          <Line data={revenueOverviewData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegend
            legendSet="set2"
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
        </div>
      </div>
    </ChartContainer>
  );
}

export default OrganicPaidImp;
