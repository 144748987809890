import axios from 'axios';
import { useQuery } from 'react-query';
import { useCurrentClientAtom } from 'atoms/clientAtom';

// COMPONENTS
import Insights from '../components/homepage/Insights';
import AutoOptimize from '../components/homepage/AutoOptimize';
import ConsumerIntent from '../components/homepage/ConsumerIntent';
import CompetitiveIntelligence from '../components/homepage/CompetitiveIntelligence';

const fetchHomepageData = async () => {
  const { data } = await axios<GETHomepageData>('/api/home/');

  return data;
};

function Home() {
  const [{ current_client }] = useCurrentClientAtom();

  const { isLoading: isLoadingSidebar } = useQuery([
    'get_customer_info_sidebar',
  ]);

  const { data, error, isLoading } = useQuery(
    ['homepageData', current_client],
    {
      queryFn: fetchHomepageData,
    }
  );

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <div className="grid grid-cols-1 xl-1400:grid-cols-2 gap-6 justify-items-center">
          <div className="w-full">
            <ConsumerIntent
              consumer_data={data?.consumer_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
          <div className="w-full">
            <CompetitiveIntelligence
              competitive_data={data?.competitive_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
          <div className="w-full">
            <AutoOptimize
              auto_data={data?.auto_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
          <div className="w-full">
            <Insights
              insights_data={data?.insights_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
