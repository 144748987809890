interface NewBusinessModalProps {
  isOpen: boolean;
  onConfirm: (isNewBusiness: boolean) => void;
}

function NewBusinessModal({ isOpen, onConfirm }: NewBusinessModalProps) {
  return isOpen ? (
    <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-light p-6 rounded shadow-md max-h-[80%]">
        <h2 className="text-primarygray text-2xl font-bold">New Business</h2>

        <div className="mt-4">Is this a new business account?</div>

        <div className="mt-4 flex justify-end">
          <button
            className="mr-2 bg-thirdgray text-light px-4 py-2 rounded"
            onClick={() => onConfirm(false)}
          >
            No
          </button>
          <button
            className="bg-success text-light px-4 py-2 rounded"
            onClick={() => onConfirm(true)}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default NewBusinessModal;
