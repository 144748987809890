import { useMemo, useState } from 'react';

const formatCategory = (category: string): string => {
  // Check if the category is already in title case and contains no hyphens
  if (/^[A-Z][a-z]*$/.test(category)) {
    return category;
  }

  // Split the category by hyphen and capitalize each word
  return category
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

interface InsightsCustomSelectorProps {
  options: string[];
  value?: string;
  maxVisible?: number;
  onChange: (value: string) => void;
}

function InsightsCustomSelector({
  options,
  value = '',
  onChange,
  maxVisible = 3,
}: InsightsCustomSelectorProps) {
  const [showDropdown, setShowDropdown] = useState(false);

  const selectedIndex = useMemo(
    () => options.indexOf(value || ''),
    [options, value]
  );

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const visibleOptions = useMemo(() => {
    const startIndex = selectedIndex > 0 ? selectedIndex - 1 : 0;
    return options.slice(startIndex, startIndex + maxVisible).map((option) => (
      <button
        key={option}
        onClick={() => {
          onChange(option);
        }}
        className={`px-4 py-2 rounded-lg ${
          option === value
            ? 'bg-light text-primarygray font-bold'
            : 'text-thirdgray'
        }`}
      >
        {formatCategory(option)}
      </button>
    ));
  }, [options, selectedIndex, value, maxVisible, onChange]);

  const overflowOptions = useMemo(() => {
    const startIndex = selectedIndex > 0 ? selectedIndex - 1 : 0;
    return options
      .filter(
        (_, index) => index < startIndex || index > startIndex + maxVisible - 1
      )
      .map((option) => (
        <div
          key={option}
          onClick={() => {
            onChange(option);
            setShowDropdown(false);
          }}
          className="px-4 py-2 hover:bg-light cursor-pointer"
        >
          {option}
        </div>
      ));
  }, [options, selectedIndex, maxVisible, onChange]);

  // HANDLE PREVIOUS CLICK
  const onClickPrev = () => {
    const currentIndex = options.indexOf(value);
    if (currentIndex > 0) {
      const previousCategory = options[currentIndex - 1];
      onChange(previousCategory);
    }
  };

  // HANDLE NEXT CLICK
  const onClickNext = () => {
    const currentIndex = options.indexOf(value);
    if (currentIndex < options.length - 1) {
      const nextCategory = options[currentIndex + 1];
      onChange(nextCategory);
    }
  };

  // JSX
  return (
    <div>
      <div className="flex justify-center my-8">
        <div className="relative overflow-y-visible">
          <div className=" flex bg-gray100 rounded-lg p-1 items-center whitespace-nowrap overflow-auto">
            <img
              src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
              alt="Previous"
              className={`h-4 w-4 mr-2 ${
                selectedIndex === 0 ? 'cursor-not-allowed' : 'cursor-pointer'
              } rotate-180 ${
                options.indexOf(value) === 0
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={onClickPrev}
            />
            {visibleOptions}
            <button
              onClick={toggleDropdown}
              className="px-4 py-2 rounded-lg text-thirdgray flex items-center"
            >
              ...
              <img
                src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                alt="Dropdown"
                className={`h-4 w-4 ml-1 transform ${
                  showDropdown ? '-rotate-90' : 'rotate-90'
                }`}
              />
            </button>

            <img
              src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
              alt="Next"
              className={`h-4 w-4 ml-2 ${
                selectedIndex === options.length - 1
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              } ${
                options.indexOf(value) === options.length - 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={onClickNext}
            />
          </div>
          {showDropdown && (
            <div className="absolute top-12 right-10 bg-light shadow-lg rounded-lg mt-2 z-[1] max-h-60 overflow-auto">
              {overflowOptions}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InsightsCustomSelector;
