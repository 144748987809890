import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// API REQUESTS
import {
  uploadFile,
  createSearchGroup,
  deleteSearchGroup,
  createGeoTargeting,
  deleteGeoTargeting,
  updateConfiguration,
  createCompetitorGroup,
  deleteCompetitorGroup,
  fetchCompetitiveIntelligenceData,
} from './api_requests';

// COMPONENTS
import Table from './Table';
import LoadingSpinner from '../../LoadingSpinner';
import GeolocationModal from './GeolocationModal';
import SearchGroupModal from './SearchGroupModal';
import CompetitorGroupModal from './CompetitorGroupModal';

// TYPES
import {
  SearchGroup,
  GeoTargeting,
  CompetitorGroup,
  CompetitiveIntProps,
} from './types';

// COMPETIVE INTEL SETTINGS PAGE
function CompetitiveInt({
  customerId,
  enabled,
  loggedInUser,
}: CompetitiveIntProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery(
    ['competitiveData', customerId],
    () => fetchCompetitiveIntelligenceData(customerId),
    {}
  );

  // EDIT CURRENT ITEM (GEOLOCATION / SEARCH GROUP / COMPETITOR GROUP)
  const [editGeolocation, setEditGeolocation] = useState<GeoTargeting | null>(
    null
  );
  const [editSearchGroup, setEditSearchGroup] = useState<SearchGroup | null>(
    null
  );
  const [editCompetitorGroup, setEditCompetitorGroup] =
    useState<CompetitorGroup | null>(null);

  const [CompIntfileCFG, setCompIntFileCFG] = useState<File | null>(null);

  const [domain, setDomain] = useState<string>('');
  const [frequency, setFrequency] = useState<number>(3);
  const [studyName, setStudyName] = useState<string>('');
  const [provider, setProvider] = useState<string>('SerpAPI');

  const [initialStudyName, setInitialStudyName] = useState<string>('');
  const [initialDomain, setInitialDomain] = useState<string>('');
  const [initialFrequency, setInitialFrequency] = useState<number>(3);
  const [initialProvider, setInitialProvider] = useState<string>('SerpAPI');

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [addConfig, setAddConfig] = useState<
    'geoTargeting' | 'searchGroup' | 'competitorGroup' | null
  >(null);

  useEffect(() => {
    if (data && data.configurations.length > 0) {
      const config = data.configurations[0];
      setStudyName(config.study_name);
      setDomain(config.domain);
      setFrequency(config.frequency);
      setProvider(config.provider);

      setInitialStudyName(config.study_name);
      setInitialDomain(config.domain);
      setInitialFrequency(config.frequency);
      setInitialProvider(config.provider);
    }
  }, [data]);

  const mutation = useMutation(updateConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
      setHasChanges(false);

      if (editGeolocation) setEditGeolocation(null);
      if (editSearchGroup) setEditSearchGroup(null);
      if (editCompetitorGroup) setEditCompetitorGroup(null);
    },
  });

  const deleteGeoMutation = useMutation(deleteGeoTargeting, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
    },
  });

  const deleteSearchMutation = useMutation(deleteSearchGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
    },
  });

  const deleteCompetitorMutation = useMutation(deleteCompetitorGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
    },
  });

  useEffect(() => {
    if (
      deleteGeoMutation.isSuccess ||
      deleteSearchMutation.isSuccess ||
      deleteCompetitorMutation.isSuccess
    ) {
      refetch();
    }
  }, [
    deleteGeoMutation.isSuccess,
    deleteSearchMutation.isSuccess,
    deleteCompetitorMutation.isSuccess,
    refetch,
  ]);

  const handleDeleteGeoTargeting = (geoId: number) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this geo targeting?'
    );
    if (userConfirmed) {
      deleteGeoMutation.mutate(geoId);
    }
  };

  const handleDeleteSearchGroup = (groupId: number) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this search group?'
    );
    if (userConfirmed) {
      deleteSearchMutation.mutate(groupId);
    }
  };

  const handleDeleteCompetitorGroup = (groupId: number) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this competitor group?'
    );
    if (userConfirmed) {
      deleteCompetitorMutation.mutate(groupId);
    }
  };

  const onUpdateGeolocation = (geoData: GeoTargeting) => {
    if (!data) return;

    const updatedConfigurations = data.configurations.map((config) => {
      if (config.geo_targeting.some((gt) => gt.geo_id === geoData.geo_id)) {
        return {
          ...config,
          geo_targeting: config.geo_targeting.map((gt) =>
            gt.geo_id === geoData.geo_id ? geoData : gt
          ),
        };
      }
      return config;
    });

    const updatedConfiguration = updatedConfigurations.find((config) =>
      config.geo_targeting.some((gt) => gt.geo_id === geoData.geo_id)
    );

    if (updatedConfiguration) {
      mutation.mutate(updatedConfiguration);
    }
  };

  const onUpdateSearchGroup = (groupData: SearchGroup) => {
    if (!data) return;

    const updatedConfigurations = data.configurations.map((config) => {
      if (
        config.search_groups.some((sg) => sg.group_id === groupData.group_id)
      ) {
        return {
          ...config,
          search_groups: config.search_groups.map((sg) =>
            sg.group_id === groupData.group_id ? groupData : sg
          ),
        };
      }
      return config;
    });

    const updatedConfiguration = updatedConfigurations.find((config) =>
      config.search_groups.some((sg) => sg.group_id === groupData.group_id)
    );

    if (updatedConfiguration) {
      mutation.mutate(updatedConfiguration);
    }
  };

  const onUpdateCompetitorGroup = (groupData: CompetitorGroup) => {
    if (!data) return;

    const updatedConfigurations = data.configurations.map((config) => {
      if (
        config.competitor_groups.some(
          (cg) => cg.group_id === (groupData as CompetitorGroup).group_id
        )
      ) {
        return {
          ...config,
          competitor_groups: config.competitor_groups.map((cg) =>
            cg.group_id === groupData.group_id ? groupData : cg
          ),
        };
      }
      return config;
    });

    const updatedConfiguration = updatedConfigurations.find((config) =>
      config.competitor_groups.some((cg) => cg.group_id === groupData.group_id)
    );

    if (updatedConfiguration) {
      mutation.mutate(updatedConfiguration);
    }
  };

  const onAddGeolocation = (geoData: GeoTargeting) => {
    createGeoTargeting(geoData)
      .then(() => {
        queryClient.invalidateQueries(['competitiveData', customerId]);
        setAddConfig(null);
      })
      .catch((err) => {
        alert(`An error occurred: ${err.message}`);
      });
  };

  const onAddSearchGroup = (groupData: SearchGroup) => {
    createSearchGroup(groupData)
      .then(() => {
        queryClient.invalidateQueries(['competitiveData', customerId]);
        setAddConfig(null);
      })
      .catch((err) => {
        alert(`An error occurred: ${err.message}`);
      });
  };

  const onAddCompetitorGroup = (groupData: CompetitorGroup) => {
    createCompetitorGroup(groupData)
      .then(() => {
        queryClient.invalidateQueries(['competitiveData', customerId]);
        setAddConfig(null);
      })
      .catch((err) => {
        alert(`An error occurred: ${err.message}`);
      });
  };

  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
      setCompIntFileCFG(null);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setCompIntFileCFG(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (hasChanges) {
      alert(
        'You have unsaved changes. Please save your changes before uploading a file.'
      );
      return; // Prevent file upload until changes are saved
    }

    if (CompIntfileCFG) {
      const userConfirmed = window.confirm(
        'Are you sure you want to upload this file?'
      );

      if (userConfirmed) {
        await uploadMutation.mutateAsync({
          CompIntfileCFG,
          customerId,
          studyName,
          domain,
          frequency,
          provider,
        });
        setCompIntFileCFG(null);
        const fileInput = document.getElementById(
          'CompIntfileCFG'
        ) as HTMLInputElement | null;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    } else {
      alert('No file selected.');
    }
  };

  const handleSaveDetails = () => {
    const userConfirmed = window.confirm(
      'Are you sure you want to save these changes?'
    );

    if (userConfirmed && data) {
      const updatedConfig = {
        ...data.configurations[0],
        study_name: studyName,
        domain,
        frequency,
        provider,
      };

      mutation.mutate(updatedConfig);
    }
  };

  const handleChange = <T,>(
    setter: React.Dispatch<React.SetStateAction<T>>,
    value: T
  ) => {
    setter(value);
    setHasChanges(true);
  };

  // Function to reset values back to initial values
  const resetToInitialValues = () => {
    setStudyName(initialStudyName);
    setDomain(initialDomain);
    setFrequency(initialFrequency);
    setProvider(initialProvider);
    setHasChanges(false);
  };

  const handleAddGeoTargeting = () => {
    setAddConfig('geoTargeting');
  };

  const handleAddSearchGroup = () => {
    setAddConfig('searchGroup');
  };

  const handleAddCompetitorGroup = () => {
    setAddConfig('competitorGroup');
  };

  const handleExportCompIntent = (customerId: string) => {
    const url = `/export-competitive-intelligence/${customerId}/`;

    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error('Failed to download the file');
        }
      })
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `competitive_intelligence_data_${customerId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isAdmin = loggedInUser?.role === 'Admin';

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch data</div>;
  }

  if (!data) {
    return enabled ? (
      <div className="bg-light border-t border-success">
        <h2 className="text-primarygray text-2xl font-bold mt-4">
          Competitive Intelligence
        </h2>
        <div className="flex items-center mt-6 grid-cols-2">
          <div className="border border-thirdgray rounded-md px-6">
            <label
              htmlFor="CompIntfileCFG"
              className="cursor-pointer text-sm font-bold flex items-center text-thirdgray"
            >
              {CompIntfileCFG
                ? CompIntfileCFG.name
                : `competitive_intelligence_study_${customerId}`}
            </label>
            <input
              type="file"
              id="CompIntfileCFG"
              className="hidden"
              accept=".xlsx"
              onChange={handleFileChange}
            />
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
            alt="refresh-icon"
            className="ml-2 cursor-pointer"
            onClick={handleFileUpload}
          />
        </div>

        <div className="flex flex-col space-y-4 py-6">
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col w-1/2">
              <label className="font-bold text-sm mb-2">Study Name</label>
              <input
                type="text"
                className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                placeholder="Us-Test-demo - Market Analysis"
                value={studyName}
                onChange={(e) => setStudyName(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="font-bold text-sm mb-2">Domain</label>
              <input
                type="text"
                className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
          </div>
          {isAdmin ? (
            <div className="flex flex-row space-x-4 py-4">
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Frequency</label>
                <input
                  type="number"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  value={3}
                  readOnly
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Provider</label>
                <select
                  className="border border-gray200 rounded p-2.5 text-thirdgray text-sm"
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                >
                  <option value="Oxylabs">Oxylabs</option>
                  <option value="SerpAPI">SerpAPI</option>
                </select>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  const geoTargetingData = data.configurations.flatMap((config) =>
    config.geo_targeting.map((gt) => ({ ...gt, id: config.study_id }))
  );
  const searchGroupsData = data.configurations.flatMap((config) =>
    config.search_groups.map((sg) => ({
      ...sg,
      id: config.study_id,
      group_id: sg.group_id,
    }))
  );
  const competitorGroupsData = data.configurations.flatMap((config) =>
    config.competitor_groups.map((cg) => ({
      ...cg,
      id: config.study_id,
      group_id: cg.group_id,
    }))
  );

  return (
    <div className="bg-light border-t border-success">
      <h2 className="text-primarygray text-2xl font-bold mt-4">
        Competitive Intelligence
      </h2>
      <div className="flex items-center mt-6 grid-cols-2">
        <div className="border border-thirdgray rounded-md px-6">
          <label
            htmlFor="CompIntfileCFG"
            className="cursor-pointer text-sm font-bold flex items-center text-thirdgray"
          >
            {CompIntfileCFG
              ? CompIntfileCFG.name
              : `competitive_intelligence_study_${customerId}`}
          </label>
          <input
            type="file"
            id="CompIntfileCFG"
            className="hidden"
            accept=".xlsx"
            onChange={handleFileChange}
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/download_green.svg`}
          alt="download_green-icon"
          className="ml-2 cursor-pointer"
          onClick={() => handleExportCompIntent(customerId)}
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
          alt="refresh-icon"
          className="ml-2 cursor-pointer"
          onClick={handleFileUpload}
        />
      </div>
      <div className="flex flex-col space-y-4 py-6">
        {data.configurations.map((config, index) => (
          <div key={index} className="flex flex-col gap-4">
            <div className="flex flex-row space-x-4">
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Study Name</label>
                <input
                  type="text"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  placeholder="Us-Test-demo - Market Analysis"
                  value={studyName}
                  onChange={(e) => handleChange(setStudyName, e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Domain</label>
                <input
                  type="text"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  value={domain}
                  onChange={(e) => handleChange(setDomain, e.target.value)}
                />
              </div>
            </div>

            {/* --- ADMIN ONLY --- */}
            {isAdmin ? (
              <div className="flex flex-row space-x-4">
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Frequency</label>
                  <input
                    type="number"
                    className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                    value={3}
                    readOnly
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Provider</label>
                  <select
                    className="border border-gray200 rounded p-2.5 text-thirdgray text-sm"
                    value={provider}
                    onChange={(e) => handleChange(setProvider, e.target.value)}
                  >
                    <option value="Oxylabs">Oxylabs</option>
                    <option value="SerpAPI">SerpAPI</option>
                  </select>
                </div>
              </div>
            ) : null}

            {/* Save & Reset Buttons */}
            <div className="flex justify-end space-x-4">
              {hasChanges && (
                <button
                  className="px-4 py-2 rounded-full bg-blue800 text-light"
                  onClick={resetToInitialValues}
                >
                  Reset
                </button>
              )}
              <button
                className={`px-4 py-2 rounded-full ${
                  hasChanges ? 'bg-success' : 'bg-gray200'
                } text-light`}
                onClick={handleSaveDetails}
                disabled={!hasChanges} // Disable the button if no changes
              >
                Save
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* #!TODO:MISSING Excel file of study */}
      <h2 className="text-primarygray text-sm font-bold">Geo Targeting</h2>
      <div className="overflow-x-auto mt-4">
        <Table<GeoTargeting>
          data={geoTargetingData}
          columns={[
            'engine',
            'device',
            'country',
            'language',
            'state',
            'city',
            'postal_code',
            'latitude',
            'longitude',
            'radius',
          ]}
          onEdit={setEditGeolocation}
          onDelete={handleDeleteGeoTargeting}
          onAdd={handleAddGeoTargeting}
        />
      </div>

      <h2 className="text-primarygray text-sm font-bold mt-6">Search Groups</h2>
      <div className="overflow-x-auto mt-4">
        <Table<SearchGroup>
          data={searchGroupsData}
          columns={['group_name', 'search_terms']}
          onEdit={setEditSearchGroup}
          onDelete={handleDeleteSearchGroup}
          onAdd={handleAddSearchGroup}
        />
      </div>

      <h2 className="text-primarygray text-sm font-bold mt-6">
        Competitor Groups
      </h2>
      <div className="overflow-x-auto mt-4">
        <Table<CompetitorGroup>
          data={competitorGroupsData}
          columns={['group_name', 'domains']}
          onEdit={setEditCompetitorGroup}
          onDelete={handleDeleteCompetitorGroup}
          onAdd={handleAddCompetitorGroup}
        />
      </div>

      {/* --- EDIT CONFIGS --- */}
      {editGeolocation ? (
        <GeolocationModal
          data={editGeolocation}
          onSubmit={onUpdateGeolocation}
          onCancel={() => {
            setEditGeolocation(null);
          }}
        />
      ) : null}

      {editSearchGroup ? (
        <SearchGroupModal
          data={editSearchGroup}
          onSubmit={onUpdateSearchGroup}
          onCancel={() => {
            setEditSearchGroup(null);
          }}
        />
      ) : null}

      {editCompetitorGroup ? (
        <CompetitorGroupModal
          data={editCompetitorGroup}
          onSubmit={onUpdateCompetitorGroup}
          onCancel={() => {
            setEditCompetitorGroup(null);
          }}
        />
      ) : null}

      {/* --- ADD CONFIGS --- */}
      {addConfig === 'geoTargeting' ? (
        <GeolocationModal
          data={null}
          onSubmit={onAddGeolocation}
          customer_id={customerId}
          onCancel={() => setAddConfig(null)}
        />
      ) : null}

      {addConfig === 'searchGroup' ? (
        <SearchGroupModal
          data={null}
          customer_id={customerId}
          onSubmit={onAddSearchGroup}
          onCancel={() => setAddConfig(null)}
        />
      ) : null}

      {addConfig === 'competitorGroup' ? (
        <CompetitorGroupModal
          data={null}
          customer_id={customerId}
          onSubmit={onAddCompetitorGroup}
          onCancel={() => setAddConfig(null)}
        />
      ) : null}
    </div>
  );
}

export default CompetitiveInt;
