import 'chart.js/auto';
import { useEffect, useState, useRef } from 'react';

// COMPONENTS
import CustomLegend from './CustomLegend';
import LoadingSpinner from './LoadingSpinner';

// TYPES
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

// UTILS
import { downloadImage, downloadXLS } from 'utils/download';

interface TransactionsPerClusterProps {
  data?: PerClusterData[];
  isError?: boolean;
  isLoading?: boolean;
  activeCluster?: string;
}

function TransactionsCluster({
  data,
  isError,
  isLoading,
}: TransactionsPerClusterProps) {
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: 'Paid Transactions',
        data: [] as number[],
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: 24,
      },
      {
        label: 'Organic Transactions',
        data: [] as number[],
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: 24,
      },
    ],
  });
  const [fullLabels, setFullLabels] = useState<string[]>([]);
  const [shortLabels, setShortLabels] = useState<string[]>([]);
  const [showFullLabels, setShowFullLabels] = useState(false);
  const chartRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data) {
      const clusters = data.map((cluster) => cluster.cluster_name);
      const labels = clusters.map(
        (category) => category.charAt(0).toUpperCase() + category.slice(1)
      );
      const shortLabels = labels.map((label) =>
        label.length > 15 ? label.substring(0, 15) + '...' : label
      );
      const paidTransactionsClusterData = data.map(
        (cluster) => cluster.paid_transactions_perc
      );
      const organicTransactionsClusterData = data.map(
        (cluster) => cluster.organic_transactions_perc
      );
      const chartWidth =
        (chartRef.current as HTMLElement | null)?.offsetWidth || 800;
      const calculatedBarThickness = Math.max(
        10,
        chartWidth / (labels.length * 2)
      );

      setFullLabels(labels);
      setShortLabels(shortLabels);
      setChartData({
        labels: shortLabels,
        datasets: [
          {
            label: 'Paid Transactions',
            data: paidTransactionsClusterData,
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThickness,
          },
          {
            label: 'Organic Transactions',
            data: organicTransactionsClusterData,
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThickness,
          },
        ],
      });
    }
  }, [data]);

  const calculateChartHeight = (labels: string[]) => {
    const labelLength = labels.reduce(
      (max, label) => Math.max(max, label.length),
      0
    );
    const baseHeight = 295;
    const extraHeight = labelLength * 5;
    return baseHeight + extraHeight;
  };

  const toggleLabels = () => {
    setShowFullLabels(!showFullLabels);
  };

  const labelsToShow = showFullLabels ? fullLabels : shortLabels;
  const chartHeight = calculateChartHeight(labelsToShow);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const index = context[0].dataIndex;
            return fullLabels[index];
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Cluster',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 90,
          minRotation: 45,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Percentage (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: function (value) {
            return value + '%';
          },
          stepSize: 25,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div>No data for this time period, please change the date range</div>
    );
  }

  const downloadChartData = () => {
    const headers = [
      'Brand',
      ...chartData.datasets.map((dataset) => dataset.label as string),
    ];
    const labels = fullLabels as string[];
    const columns = chartData.datasets.map(
      (dataset) => dataset.data as number[]
    );

    downloadXLS('transactions_per_cluster', headers, labels, columns);
  };

  const downloadChartImage = () => {
    downloadImage('transactions_per_cluster');
  };

  return (
    <div
      id="transactions_per_cluster"
      className="bg-light rounded-2xl shadow-sm mt-8"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">
            Transactions per Cluster
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            Understand evolution of holistic search transaction per cluster
          </h3>
        </div>
        <div className="flex items-center mr-4 text-xs">
          <span
            className={
              showFullLabels ? 'text-primarygray mr-2' : 'text-gray400 mr-2'
            }
          >
            Short
          </span>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={showFullLabels}
              onChange={toggleLabels}
            />
            <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
          </label>
          <span
            className={
              showFullLabels ? 'text-primarygray ml-2' : 'text-gray400 ml-2'
            }
          >
            Full
          </span>
          <div
            className="flex items-center"
            ref={dropdownRef}
            id="downloadDropdownMenu-transactions_per_cluster"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 ml-2 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                  >
                    Download XLS
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex relative">
        <div
          className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[90%] xl:w-[90%] md:w-[90%] w-[90%]"
          style={{ height: chartHeight }}
        >
          <Bar
            data={{ ...chartData, labels: labelsToShow }}
            options={options}
          />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legendSet="set4" />
        </div>
      </div>
    </div>
  );
}

export default TransactionsCluster;
