import 'chart.js/auto';
import moment from 'moment';
import { useQuery } from 'react-query';
import { addDays, subDays } from 'date-fns';
import React, { useState, useRef, useEffect } from 'react';
import { downloadImage, downloadXLS } from 'utils/download';

// COMPONENTS
import { Chart } from 'react-chartjs-2';
import LoadingSpinner from '../LoadingSpinner';
import FilterBarWrapper from '../FilterBarWrapper';
import DatepickerComponent from '../DatepickerComponent';
import CustomLegend from './cluster_components/CustomLegend';

// TYPES
import { ChartData, ChartOptions } from 'chart.js';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { toFixedNum } from 'utils';

interface CardProps {
  title: string;
  value: string;
  change: string;
  isPositive: boolean | null; // updated to handle null for zero values
  styleType: 'light' | 'dark';
}

interface InsightsData {
  comp_Days: number;
  summary: {
    [key: string]: number;
  };
  percentage_difference: {
    [key: string]: number | null;
  };
  weekly_summary: {
    [key: string]: {
      [key: string]: number;
    };
  };
}

// OVERVIEW CHART TYPES
const CHART_TYPES = ['Impressions', 'Clicks', 'Transactions', 'Revenue'];

// INTENT -> OVERVIEW CARD COMPONENT
function Card({ title, value, change, isPositive, styleType }: CardProps) {
  const bgColor = styleType === 'light' ? 'bg-light' : 'bg-secondarygray';
  const textColor = styleType === 'light' ? 'text-primarygray' : 'text-light';
  const changeColor =
    isPositive === true
      ? 'text-success'
      : isPositive === false
      ? 'text-error'
      : 'text-thirdgray';

  // JSX
  return (
    <div
      className={`${bgColor} p-4 rounded-2xl text-center ${
        styleType === 'light' && 'dark'
      }`}
    >
      <p className={`${textColor} text-sm font-medium mb-2`}>{title}</p>
      <h3 className={`${textColor} text-2xl font-bold mb-2`}>{value}</h3>
      <div
        className={`${changeColor} flex items-center justify-center text-sm font-medium`}
      >
        {change !== 'N/A' ? (
          <>
            {isPositive !== null && (
              <img
                src={`${process.env.PUBLIC_URL}/static/img/Arrow_${
                  isPositive ? 'positive' : 'red'
                }.svg`}
                alt={isPositive ? 'Positive Change' : 'Negative Change'}
                className="w-4 h-4 mr-1"
              />
            )}
            {change}
          </>
        ) : (
          <span className="text-thirdgray">--</span>
        )}
      </div>
    </div>
  );
}

// INTENT -> OVERVIEW PAGE
function Overview() {
  const [dropdownState, setDropdownState] = useState<{
    [key: string]: boolean;
  }>({});
  const [isCardDropdownOpen, setIsCardDropdownOpen] = useState(false);
  const dropdownRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const cardDropdownRef = useRef<HTMLDivElement>(null);
  const [cardData, setCardData] = useState<CardProps[]>([]);
  const [chartDataMap, setChartDataMap] = useState<{
    [key: string]: ChartData<'bar' | 'line'>;
  }>({});
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: addDays(new Date(), -90), // 90 days before today
    endDate: subDays(new Date(), 3),
  });

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  const fetchData = async (
    startDate: string,
    endDate: string
  ): Promise<InsightsData> => {
    const response = await fetch(`/get_insights_data/${startDate}/${endDate}`);
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === "'json_data'") {
        throw new Error(
          'No data for this time period, please change the date range'
        );
      }
      throw new Error('Network response was not ok');
    }
    return await response.json();
  };

  const { startDate, endDate } = dateRange;
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  const { data, isLoading, isError } = useQuery<InsightsData, Error>(
    ['insightsData', formattedStartDate, formattedEndDate],
    () => {
      return fetchData(formattedStartDate, formattedEndDate);
    }
  );

  const checkAllZero = (data: number[]): boolean => {
    return data.every((value) => value === 0);
  };

  useEffect(() => {
    if (!data) return;

    // UPDATE COMP DAYS DIFFERENCE
    const start = moment(dateRange.startDate || 'defaultStartDate');
    const end = moment(dateRange.endDate || 'defaultEndDate');
    const compDays = end.diff(start, 'days');

    setCompDays(compDays);

    // GET SUMMARY CARDS DATA
    const getPercentageChange = (value: number | null) =>
      value !== null ? `${toFixedNum(value)}%` : 'N/A';
    const getIsPositive = (value: number | null) =>
      value !== null ? (value > 0 ? true : value < 0 ? false : null) : null;

    const summaryData: CardProps[] = [
      {
        title: 'Organic Impressions',
        value: toFixedNum(data.summary.organic_impressions),
        change: getPercentageChange(
          data.percentage_difference.organic_impressions
        ),
        isPositive: getIsPositive(
          data.percentage_difference.organic_impressions
        ),
        styleType: 'light' as const,
      },
      {
        title: 'Organic Clicks',
        value: toFixedNum(data.summary.organic_clicks),
        change: getPercentageChange(data.percentage_difference.organic_clicks),
        isPositive: getIsPositive(data.percentage_difference.organic_clicks),
        styleType: 'light' as const,
      },
      {
        title: 'Organic Transactions',
        value: toFixedNum(data.summary.organic_transactions),
        change: getPercentageChange(
          data.percentage_difference.organic_transactions
        ),
        isPositive: getIsPositive(
          data.percentage_difference.organic_transactions
        ),
        styleType: 'light' as const,
      },
      {
        title: 'Organic Revenue',
        value: `$${toFixedNum(data.summary.organic_revenue)}`,
        change: getPercentageChange(data.percentage_difference.organic_revenue),
        isPositive: getIsPositive(data.percentage_difference.organic_revenue),
        styleType: 'light' as const,
      },
      {
        title: 'Paid Impressions',
        value: toFixedNum(data.summary.paid_impressions),
        change: getPercentageChange(
          data.percentage_difference.paid_impressions
        ),
        isPositive: getIsPositive(data.percentage_difference.paid_impressions),
        styleType: 'light' as const,
      },
      {
        title: 'Paid Clicks',
        value: toFixedNum(data.summary.paid_clicks),
        change: getPercentageChange(data.percentage_difference.paid_clicks),
        isPositive: getIsPositive(data.percentage_difference.paid_clicks),
        styleType: 'light' as const,
      },
      {
        title: 'Paid Transactions',
        value: toFixedNum(data.summary.paid_transactions),
        change: getPercentageChange(
          data.percentage_difference.paid_transactions
        ),
        isPositive: getIsPositive(data.percentage_difference.paid_transactions),
        styleType: 'light' as const,
      },
      {
        title: 'Paid Revenue',
        value: `$${toFixedNum(data.summary.paid_revenue)}`,
        change: getPercentageChange(data.percentage_difference.paid_revenue),
        isPositive: getIsPositive(data.percentage_difference.paid_revenue),
        styleType: 'light' as const,
      },
      {
        title: 'Holistic Impressions',
        value: toFixedNum(data.summary.holistic_impressions),
        change: getPercentageChange(
          data.percentage_difference.holistic_impressions
        ),
        isPositive: getIsPositive(
          data.percentage_difference.holistic_impressions
        ),
        styleType: 'dark' as const,
      },
      {
        title: 'Holistic Clicks',
        value: toFixedNum(data.summary.holistic_clicks),
        change: getPercentageChange(data.percentage_difference.holistic_clicks),
        isPositive: getIsPositive(data.percentage_difference.holistic_clicks),
        styleType: 'dark' as const,
      },
      {
        title: 'Holistic Transactions',
        value: toFixedNum(data.summary.holistic_transactions),
        change: getPercentageChange(
          data.percentage_difference.holistic_transactions
        ),
        isPositive: getIsPositive(
          data.percentage_difference.holistic_transactions
        ),
        styleType: 'dark' as const,
      },
      {
        title: 'Holistic Revenue',
        value: `$${toFixedNum(data.summary.holistic_revenue)}`,
        change: getPercentageChange(
          data.percentage_difference.holistic_revenue
        ),
        isPositive: getIsPositive(data.percentage_difference.holistic_revenue),
        styleType: 'dark' as const,
      },
    ];

    setCardData(summaryData);

    // GET CHART DATA
    const chartDataObj: { [key: string]: ChartData<'bar' | 'line'> } = {};

    for (const chartType of CHART_TYPES) {
      const chartLabels = Object.keys(data.weekly_summary).sort();

      const organicData = chartLabels.map(
        (label) =>
          data.weekly_summary[label][`organic_${chartType.toLowerCase()}`]
      );
      const paidData = chartLabels.map(
        (label) => data.weekly_summary[label][`paid_${chartType.toLowerCase()}`]
      );
      const holisticData = chartLabels.map(
        (label) =>
          data.weekly_summary[label][`holistic_${chartType.toLowerCase()}`]
      );

      if (
        !checkAllZero(organicData) ||
        !checkAllZero(paidData) ||
        !checkAllZero(holisticData)
      ) {
        chartDataObj[chartType] = {
          labels: chartLabels.map((label) => label.split('(')[1].split(' ')[0]),
          datasets: [
            {
              type: 'bar' as const,
              label: `Organic ${chartType}`,
              data: organicData,
              backgroundColor: 'rgba(34, 34, 34, 0.80)',
              borderWidth: 1,
              borderRadius: 8,
              barThickness: 24,
            },
            {
              type: 'bar' as const,
              label: `Paid ${chartType}`,
              data: paidData,
              backgroundColor: 'rgba(144, 144, 144, 0.80)',
              borderWidth: 1,
              borderRadius: 8,
              barThickness: 24,
            },
            {
              type: 'line' as const,
              label: `Holistic ${chartType}`,
              data: holisticData,
              backgroundColor: 'rgba(217, 217, 217, 0.80)',
              borderColor: '#909090CC',
              borderWidth: 1.5,
              fill: false,
              tension: 0.4,
              pointBackgroundColor: '#FFFFFF',
              pointBorderColor: '#909090CC',
              pointRadius: 2,
            },
          ],
        };
      }
    }

    setChartDataMap(chartDataObj);
  }, [data]);

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(0)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(0)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Week',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#222',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#222',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const [compDays, setCompDays] = useState<number>();

  const toggleDropdown = (chartType: string) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [chartType]: !prevState[chartType],
    }));
  };

  const toggleCardDropdown = () => {
    setIsCardDropdownOpen(!isCardDropdownOpen);
  };

  const closeDropdown = (chartType: string) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [chartType]: false,
    }));
  };

  const closeCardDropdown = () => {
    setIsCardDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      Object.keys(dropdownRefs.current).forEach((chartType) => {
        const dropdownElement = dropdownRefs.current[chartType];
        if (
          dropdownElement &&
          !dropdownElement.contains(event.target as Node)
        ) {
          closeDropdown(chartType);
        }
      });

      if (
        cardDropdownRef.current &&
        !cardDropdownRef.current.contains(event.target as Node)
      ) {
        closeCardDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getSubtitle = (tab: string): string => {
    switch (tab) {
      case 'Impressions':
        return 'Understand how holistic search impressions evolve';
      case 'Clicks':
        return 'Understand how holistic search clicks evolve';
      case 'Transactions':
        return 'Understand how holistic search transactions evolve';
      case 'Revenue':
        return 'Understand how holistic search revenue evolve';
      default:
        return 'Subtitle';
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <>
        <div className="flex justify-end items-center rounded-2xl text-sm bg-light h-14 mt-12 mb-8 pr-4">
          <DatepickerComponent
            value={dateRange}
            onChange={handleValueChange}
            product="insights"
          />
        </div>
        <div>No data for this time period, please change the date range</div>
      </>
    );
  }

  const downloadCardData = () => {
    const headers = ['Title', 'Value', 'Change'];
    const labels = cardData.map((card) => card.title);
    const columns = [
      cardData.map((card) => card.value),
      cardData.map((card) => card.change),
    ];
    downloadXLS('Card_Data', headers, labels, columns);
  };

  const downloadCardImage = () => {
    downloadImage('Insights_Overview');
  };

  const downloadChartDataAsXLS = (
    chartType: string,
    chartData: ChartData<'bar' | 'line'>
  ) => {
    const headers = ['Week', 'Organic', 'Paid', 'Holistic'];
    const labels = chartData.labels as string[];
    const organicData = chartData.datasets[0].data as number[];
    const paidData = chartData.datasets[1].data as number[];
    const holisticData = chartData.datasets[2].data as number[];

    const columns = [organicData, paidData, holisticData];

    downloadXLS(`${chartType}_Data`, headers, labels, columns);
  };

  const downloadChartAsImage = (chartType: string) => {
    downloadImage(`chart_image_${chartType}`);
  };

  // JSX
  return (
    <div className="pt-8">
      <FilterBarWrapper subText="">
        <div className="flex-1" />
        <DatepickerComponent
          value={dateRange}
          onChange={handleValueChange}
          product="insights"
        />
      </FilterBarWrapper>
      <div className="bg-transparent border-b border-success mb-4 pb-2 flex justify-between items-center mt-6">
        <div>
          <h2 className="text-primarygray text-2xl font-bold">Summary</h2>
          <p className="text-secondarygray text-sm font-bold tabular-nums">
            Comparing to last {compDays} days
          </p>
        </div>
        <div className="relative" ref={cardDropdownRef}>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleCardDropdown}
          />
          {isCardDropdownOpen && (
            <div
              className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeCardDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadCardData();
                    closeCardDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadCardImage();
                    closeCardDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div
        id="Insights_Overview"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-8"
      >
        {cardData.map((item, index) => (
          <Card
            key={index}
            title={item.title}
            value={item.value}
            change={item.change}
            isPositive={item.isPositive}
            styleType={item.styleType}
          />
        ))}
      </div>
      {Object.entries(chartDataMap).map(([chartType, updatedChartData]) => (
        <div
          key={chartType}
          className="bg-light rounded-2xl shadow-sm mt-8"
          id={`chart_image_${chartType}`}
        >
          <div className="flex justify-between items-center border-b border-success mb-2">
            <div className="flex flex-col">
              <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">
                {chartType}
              </h2>
              <h3 className="text-primarygray text-sm font-bold pl-4 pb-2">
                {getSubtitle(chartType)}
              </h3>
            </div>
            <div
              className="relative"
              ref={(el) => (dropdownRefs.current[chartType] = el)}
              id={`downloadDropdownMenu-chart_image_${chartType}`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
                alt="dots"
                className="h-6 w-6 mr-4 cursor-pointer"
                onClick={() => toggleDropdown(chartType)}
              />
              {dropdownState[chartType] && (
                <div
                  className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                  style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
                >
                  <ul>
                    {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        downloadChartDataAsXLS(chartType, updatedChartData);
                        closeDropdown(chartType);
                      }}
                    >
                      Download XLS
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        downloadChartAsImage(chartType);
                        closeDropdown(chartType);
                      }}
                    >
                      Download PNG image
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex relative">
            <div className="flex px-2 py-6 justify-center items-center md:h-[295px] w-[85%] h-[295px]">
              <Chart type="bar" data={updatedChartData} options={options} />
            </div>
            <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
              <CustomLegend legendSet="set2" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Overview;
