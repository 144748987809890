import { useEffect, useState } from 'react';

// COMPONENTS
import FormModal from './FormModal';

// TYPES
import { SearchGroup } from './types';

const FORM_ID = 'search-group-modal-form';

const DEFAULT_DATA: SearchGroup = {
  customer_id: '',
  id: 0,
  group_id: 0,
  group_name: '',
  search_terms: '',
};

interface SearchGroupModalProps {
  data: SearchGroup | null;
  customer_id?: string;
  onCancel: () => void;
  onSubmit: (updatedData: SearchGroup) => void;
}

// COMP INTEL EDIT GEOLOCATION MODAL (SETTINGS)
function SearchGroupModal({
  data,
  customer_id,
  onCancel,
  onSubmit,
}: SearchGroupModalProps) {
  const [isError, setIsError] = useState(false);
  const [formState, setFormState] = useState({ ...DEFAULT_DATA });

  useEffect(() => {
    if (data) {
      setFormState({ ...data });
    }
    if (customer_id) {
      setFormState({ ...DEFAULT_DATA, customer_id });
    }
  }, [data, customer_id]);

  const onUpdate = (evt: React.FormEvent) => {
    evt.preventDefault();
    onSubmit(formState);
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  // JSX
  return (
    <FormModal
      header={`${data ? 'Edit' : 'Add'} Search Group`}
      formId={FORM_ID}
      onCancel={onCancel}
      isError={isError}
    >
      <form
        onSubmit={onUpdate}
        id={FORM_ID}
        onInvalid={() => {
          setIsError(true);
        }}
      >
        <label className="block text-sm font-medium text-primarygray">
          Group Name*
        </label>
        <input
          type="text"
          className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
          name="group_name"
          value={formState.group_name || ''}
          onChange={onChange}
          required
        />
        <label className="block text-sm font-medium text-primarygray">
          Search Terms*
        </label>
        <input
          type="text"
          className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
          name="search_terms"
          value={formState.search_terms || ''}
          onChange={onChange}
          required
        />
      </form>
    </FormModal>
  );
}

export default SearchGroupModal;
