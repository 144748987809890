import 'chart.js/auto';
import { useState, useRef, useEffect } from 'react';

// COMPONENTS
import BCLineChart from './BCLineChart';
import { Chart } from 'react-chartjs-2';
import CustomLegend from './CustomLegend';
import MultiDropdown from '../../MultiDropdown';

// TYPES
import {
  Plugin,
  Element,
  ChartOptions,
  PointElement,
  Chart as ChartJS,
} from 'chart.js';
import { ChartData } from 'chart.js';

// UTILS
import { downloadXLS, downloadImage } from 'utils/download';

interface BrandMarketOverviewProps {
  data: {
    share_of_search_bar_chart: {
      total_demand_per_brand: { Brand: string; brand_total_demand: number }[];
      calculated_share_of_search_per_brand: {
        Brand: string;
        calculated_share_of_search: number;
      }[];
    };
    timeseries_chart: {
      total_demand_per_period: {
        period_formatted: string;
        total_demand: number;
        total_brand_demand: number;
      }[];
    };
  };
}

function BrandMarketOverview({ data }: BrandMarketOverviewProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS | null>(null);

  // Initialize selectedBrands to top 5 brands based on demand
  const topBrands = data.share_of_search_bar_chart.total_demand_per_brand
    .sort((a, b) => b.brand_total_demand - a.brand_total_demand)
    .slice(0, 5)
    .map((item) => item.Brand);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(topBrands);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { share_of_search_bar_chart } = data;

  const labels = share_of_search_bar_chart.total_demand_per_brand.map(
    (brand) => brand.Brand
  );

  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Brand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: 4000000,
          callback: function (value) {
            const numericValue = Number(value);
            return numericValue >= 1000000
              ? numericValue / 1000000 + 'M'
              : numericValue >= 1000
              ? numericValue / 1000 + 'K'
              : numericValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y1: {
        position: 'right',
        title: {
          display: true,
          text: 'Share of Search (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: function (value) {
            const numericValue = Number(value);
            return numericValue;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const drawLabel = (chart: ChartJS) => {
    const ctx = chart.ctx;
    ctx.save();

    // const barMeta = chart.getDatasetMeta(0);
    const lineMeta = chart.getDatasetMeta(1);

    lineMeta.data.forEach((point: Element<PointElement>, index) => {
      const value = chart.data.datasets[1].data[index] as number;
      const model = point as PointElement;

      ctx.font = 'bold 12px DM Sans';
      ctx.textAlign = 'center';
      ctx.shadowBlur = 3.5;
      ctx.shadowColor = '#FFFFFF';
      ctx.fillStyle = '#000000';
      // if line y is greater than bar y than line point lands inside bar.  otherwise it's above bar
      // model.y - 10 >= barMeta.data[index].y ? '#d4d4d4' : '#747474';
      ctx.fillText(value.toFixed(2) + '%', model.x, model.y - 8);
    });

    ctx.restore();
  };

  const customPlugin: Plugin<'bar' | 'line'> = {
    id: 'customPlugin',
    afterDatasetsDraw: drawLabel,
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // const handleBrandSelect = (brand: string) => {
  //   setSelectedBrands((prev) => {
  //     const newBrands = prev.includes(brand)
  //       ? prev.filter((b) => b !== brand)
  //       : [...prev, brand];
  //     return newBrands;
  //   });
  // };

  const filteredBrands = selectedBrands.filter(
    (brand) => brand.toLowerCase() !== 'uncategorized'
  );

  const filteredTotalDemandData =
    share_of_search_bar_chart.total_demand_per_brand
      .filter(({ Brand }) => filteredBrands.includes(Brand))
      .map((brand) => brand.brand_total_demand);

  const filteredShareOfSearchData =
    share_of_search_bar_chart.calculated_share_of_search_per_brand
      .filter(({ Brand }) => filteredBrands.includes(Brand))
      .map((brand) =>
        parseFloat((brand.calculated_share_of_search * 100).toFixed(2))
      );

  const filteredLabels = share_of_search_bar_chart.total_demand_per_brand
    .filter(({ Brand }) => filteredBrands.includes(Brand))
    .map((brand) => brand.Brand);

  const chartWidth =
    (chartRef.current as HTMLElement | null)?.offsetWidth || 800;
  const calculatedBarThickness = Math.max(
    10,
    chartWidth / (filteredLabels.length * 2)
  );

  const filteredBrandMarketData: ChartData<'bar' | 'line'> = {
    labels: filteredLabels,
    datasets: [
      {
        type: 'bar' as const,
        label: 'Demand',
        data: filteredTotalDemandData,
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: calculatedBarThickness,
        yAxisID: 'y',
      },
      {
        type: 'line' as const,
        label: 'Share of Search (%)',
        data: filteredShareOfSearchData,
        borderColor: 'rgba(89, 89, 89, 0.80)',
        borderWidth: 1,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: 'rgba(89, 89, 89, 0.80)',
        yAxisID: 'y1',
        tension: 0.4,
      },
    ],
  };

  const downloadChartData = () => {
    const barChartHeaders = ['Brand', 'Demand', 'Share of Search (%)'];
    const barChartLabels = share_of_search_bar_chart.total_demand_per_brand.map(
      (brand) => brand.Brand
    );
    const barChartColumns = [
      share_of_search_bar_chart.total_demand_per_brand.map(
        (brand) => brand.brand_total_demand
      ),
      share_of_search_bar_chart.calculated_share_of_search_per_brand.map(
        (brand) => brand.calculated_share_of_search * 100
      ),
    ];

    downloadXLS(
      'Brand_Market_Overview_Share_of_Search',
      barChartHeaders,
      barChartLabels,
      barChartColumns
    );

    const timeSeriesHeaders = ['Month', 'Total Demand', 'Brand Demand'];
    const timeSeriesLabels = data.timeseries_chart.total_demand_per_period.map(
      (item) => item.period_formatted
    );
    const timeSeriesColumns = [
      data.timeseries_chart.total_demand_per_period.map(
        (item) => item.total_demand
      ),
      data.timeseries_chart.total_demand_per_period.map(
        (item) => item.total_brand_demand
      ),
    ];

    downloadXLS(
      'Brand_Market_Overview_Time_Series',
      timeSeriesHeaders,
      timeSeriesLabels,
      timeSeriesColumns
    );
  };

  const downloadChartImage = () => {
    downloadImage('Brand_Market_Overview_Chart');
  };

  return (
    <div
      id="Brand_Market_Overview_Chart"
      className="bg-light rounded-2xl shadow-sm mt-8"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Share of Search (Brand and Market Overview)
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            How consumers search for brands
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-Brand_Market_Overview_Chart"
        >
          {/* <CustomDropdown
            title={selectedBrands.length > 0 ? selectedBrands.join(', ') : 'Brand'}
            options={[...labels]}
            onOptionClick={handleBrandSelect}
          /> */}
          <MultiDropdown
            placeholder={'Brand'}
            preselectedOptions={selectedBrands}
            options={[...labels]}
            dropdownLength="max-h-72"
            dropdownWidth="max-w-72"
            // onOptionClick={handleBrandSelect}
            onSelectionChange={setSelectedBrands}
          />
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={closeDropdown}
                >
                  View data table
                </li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Chart
            type="bar"
            data={filteredBrandMarketData}
            options={options}
            plugins={[customPlugin]}
          />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 relative 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <div className="flex items-center">
            <CustomLegend legendSet="set1" />
            <div className="relative ml-[15px] mb-8 group inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute top-full left-0 transform -translate-x-[230px] mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className="px-4 py-2">
                  Share of Search is the % of demand for a<br></br> given brand
                  within your total category<br></br> demand
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute right-0 transform translate-x-8 -top-[6px] rotate-180"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <BCLineChart data={data.timeseries_chart} />
    </div>
  );
}

export default BrandMarketOverview;
