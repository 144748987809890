import { useEffect, useRef } from 'react';

interface FormModalProps {
  formId: string;
  header: string;
  isError?: boolean;
  children: React.ReactNode;
  onCancel: () => void;
}

function FormModal({
  formId,
  header,
  children,
  isError = false,
  onCancel,
}: FormModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isError && modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [isError]);

  // JSX
  return (
    <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center z-50">
      <div
        className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-[80%]"
        ref={modalRef}
      >
        <h2 className="text-primarygray text-2xl font-bold">{header}</h2>

        {isError ? (
          <div className="text-center text-error mt-2">
            Please enter a value for all required fields.
          </div>
        ) : null}

        <div className="mt-4">{children}</div>

        <div className="mt-4 flex justify-end">
          <button
            className="mr-2 bg-thirdgray text-light px-4 py-2 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            form={formId}
            className="bg-success text-light px-4 py-2 rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormModal;
