import { useEffect, useState } from 'react';
import AddClientModal from './AddClientModal';
import { useQuery } from 'react-query';

const sortOptionsFunc = (
  a: Agency | Region | Market | LineOfBusiness,
  b: Agency | Region | Market | LineOfBusiness
) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};

const fetchAvailableData = async () => {
  const response = await fetch('/get_available_data_base_set_up/');
  const data: { agencies: Agency[] } = await response.json();
  return data.agencies;
};

function BaseSetup({ formData, setFormData }: BaseSetupProps) {
  const [addNewClient, setAddNewClient] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState<LineOfBusiness[]>([]);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { data: agencies = [] } = useQuery({
    queryKey: ['base_setup_data'],
    queryFn: fetchAvailableData,
  });

  useEffect(() => {
    if (formData.agency) {
      const selectedAgency = agencies.find(
        (agency) => agency.id === Number(formData.agency)
      );
      if (selectedAgency) {
        setClients(selectedAgency.clients);
      }
      setErrors((prevErrors) => ({ ...prevErrors, agency: '' }));
    } else {
      setClients([]);
      setErrors((prevErrors) => ({
        ...prevErrors,
        agency: 'Agency is required.',
      }));
    }
  }, [formData.agency, agencies]);

  useEffect(() => {
    if (formData.client) {
      const selectedClient = clients.find(
        (client) => client.id === Number(formData.client)
      );
      if (selectedClient) {
        setLinesOfBusiness(selectedClient.lines_of_business || []);
        // If no LOB, use available markets at the client level
        setMarkets(selectedClient.available_markets || []);
      }
      setErrors((prevErrors) => ({ ...prevErrors, client: '' }));
    } else {
      setLinesOfBusiness([]);
      setMarkets([]);
      if (formData.agency) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          client: 'Client is required.',
        }));
      }
    }
  }, [formData.client, clients]);

  useEffect(() => {
    if (formData.lineOfBusiness) {
      const selectedLOB = linesOfBusiness.find(
        (lob) => lob.id === Number(formData.lineOfBusiness)
      );
      if (selectedLOB) {
        setMarkets(selectedLOB.available_markets || []);
      }
      setErrors((prevErrors) => ({ ...prevErrors, lineOfBusiness: '' }));
    } else {
      // If no LOB is selected, use client-level markets
      const selectedClient = clients.find(
        (client) => client.id === Number(formData.client)
      );
      if (selectedClient) {
        setMarkets(selectedClient.available_markets || []);
      }
    }
  }, [formData.lineOfBusiness, linesOfBusiness, clients, formData.client]);

  useEffect(() => {
    if (formData.market) {
      const selectedMarket = markets.find(
        (market) => market.name === formData.market
      );
      if (selectedMarket) {
        setRegions([selectedMarket.region]);
        setFormData((prevState) => ({
          ...prevState,
          region: selectedMarket.region.name,
        }));
      }
      setErrors((prevErrors) => ({ ...prevErrors, market: '' }));
    } else {
      setRegions([]);
      setErrors((prevErrors) => ({
        ...prevErrors,
        market: 'Market is required.',
      }));
    }
  }, [formData.market, markets]);

  useEffect(() => {
    if (formData.region) {
      setErrors((prevErrors) => ({ ...prevErrors, region: '' }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        region: 'Region is required.',
      }));
    }
  }, [formData.region]);

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = evt.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // JSX
  return (
    <div>
      <h2 className="text-[32px] font-bold">Base Setup</h2>
      <h3 className="font-bold text-grayscale70 mt-2">Initial configuration</h3>
      <form className="mt-6 space-y-6">
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">
            Agency
          </label>
          <select
            name="agency"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.agency}
            onChange={onChange}
          >
            <option value="">Select</option>
            {agencies
              .slice()
              .sort(sortOptionsFunc)
              .map((agency) => (
                <option key={agency.id} value={agency.id}>
                  {agency.name}
                </option>
              ))}
          </select>
          {errors.agency && (
            <span className="text-error text-sm">{errors.agency}</span>
          )}
        </div>
        <div>
          <div className="flex justify-between items-center">
            <label className="font-bold text-primarygray text-2xl mb-2">
              Client
            </label>
            {formData.new_business ? (
              <button
                type="button"
                className="border-solid border border-thirdgray rounded-full py-0.5 px-2 w-fit text-thirdgray font-bold flex items-center"
                onClick={(evt) => {
                  evt.preventDefault();
                  setAddNewClient(true);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
                  alt="plus_icon"
                  className="w-[18px] h-[18px] mr-1"
                />
                Add Client
              </button>
            ) : null}
          </div>
          <select
            name="client"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.client}
            onChange={onChange}
            disabled={!formData.agency}
          >
            <option value="">Select</option>
            {clients.sort(sortOptionsFunc).map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
          {errors.client && (
            <span className="text-error text-sm">{errors.client}</span>
          )}
        </div>
        {linesOfBusiness.length > 0 && (
          <div>
            <label className="block font-bold text-primarygray text-2xl mb-2">
              Line of Business
            </label>
            <select
              name="lineOfBusiness"
              className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
              value={formData.lineOfBusiness}
              onChange={onChange}
            >
              <option value="">Select</option>
              {linesOfBusiness.sort(sortOptionsFunc).map((lob) => (
                <option key={lob.id} value={lob.id}>
                  {lob.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">
            Market
          </label>
          <select
            name="market"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.market}
            onChange={onChange}
          >
            <option value="">Select</option>
            {markets.sort(sortOptionsFunc).map((market) => (
              <option key={market.id} value={market.name}>
                {market.name}
              </option>
            ))}
          </select>
          {errors.market && (
            <span className="text-error text-sm">{errors.market}</span>
          )}
        </div>
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">
            Region
          </label>
          <select
            name="region"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.region}
            onChange={onChange}
            disabled={true}
          >
            {regions.sort(sortOptionsFunc).map((region) => (
              <option key={region.id} value={region.name}>
                {region.name}
              </option>
            ))}
          </select>
          {errors.region && (
            <span className="text-error text-sm">{errors.region}</span>
          )}
        </div>
        {formData.new_business ? (
          <div className="inline-flex items-center mt-4 cursor-not-allowed">
            <input
              type="checkbox"
              name="new_business"
              className="w-3 h-3 cursor-not-allowed"
              checked={formData.new_business}
              disabled
            />
            <label className="ml-2 text-thirdgray cursor-not-allowed">
              Is New Business
            </label>
          </div>
        ) : null}
      </form>
      {addNewClient ? (
        <AddClientModal
          agencies={agencies}
          defaultAgency={formData.agency}
          onCancel={() => setAddNewClient(false)}
        />
      ) : null}
    </div>
  );
}

export default BaseSetup;
