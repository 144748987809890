import 'chart.js/auto';
import { useState, useEffect, useMemo } from 'react';

// COMPONENTS
import Dropdown from '../Dropdown';
import { Bar } from 'react-chartjs-2';
import ChangeLogB from './ChangeLogB';
import CustomLegend2 from './CustomLegend2';
import ChartContainer from './ChartContainer';
import ChangeLogTable from './ChangeLogTable';

export interface ChangeLogItem {
  keyword: string;
  on_count: number;
  off_count: number;
}

export interface ChangeLogData {
  change_log: Record<string, ChangeLogItem[]>;
  change_log_daily: Record<string, { on_count: number; off_count: number }>;
  change_log_summary: {
    on: number;
    off: number;
  };
  change_log_table: ChangeLogTableRowData[];
}

// TYPES
import { ChartOptions } from 'chart.js';
import { ChangeLogTableRowData } from 'types';

type ChangeLog = {
  [date: string]: ChangeLogItem[];
};

const aggregateDailyData = (
  changeLog: ChangeLog,
  keyword: string
): { [date: string]: { on_count: number; off_count: number } } => {
  const dailyData: {
    [date: string]: { on_count: number; off_count: number };
  } = {};

  for (const date in changeLog) {
    changeLog[date].forEach((item) => {
      if (item.keyword == keyword) {
        if (!dailyData[date]) {
          dailyData[date] = { on_count: 0, off_count: 0 };
        }
        dailyData[date].on_count += item.on_count;
        dailyData[date].off_count += item.off_count;
      }
    });
  }
  return dailyData;
};

// AUTO OPTIMIZE CHANGE LOG VISUALS
function ChangeLog({
  change_log,
  change_log_daily,
  change_log_table,
}: ChangeLogData) {
  const [selectedKeyword, setSelectedKeyword] = useState(
    'All Negative Keywords'
  );
  const [labels, setLabels] = useState<string[]>([]);
  const [on_count, setOnCount] = useState<number[]>([]);
  const [off_count, setOffCount] = useState<number[]>([]);

  const collectUniqueKeywords = (changeLog: ChangeLog): Set<string> => {
    const availableKeywords = new Set<string>();
    availableKeywords.add('All Negative Keywords');
    for (const entries of Object.values(changeLog)) {
      for (const entry of entries) {
        availableKeywords.add(entry.keyword);
      }
    }
    return availableKeywords;
  };

  const availableKeywords = Array.from(collectUniqueKeywords(change_log));

  const newXlsDates: string[] = [];
  const newXlsKeywords: string[] = [];
  const newXlsOnCounts: number[] = [];
  const newXlsOffCounts: number[] = [];

  Object.entries(change_log).forEach(([date, items]) => {
    items.forEach((item) => {
      newXlsDates.push(date);
      newXlsKeywords.push(item.keyword);
      newXlsOnCounts.push(item.on_count);
      newXlsOffCounts.push(item.off_count);
    });
  });

  useEffect(() => {
    const newLabels: string[] = [];
    const newOnCount: number[] = [];
    const newOffCount: number[] = [];

    if (selectedKeyword === 'All Negative Keywords') {
      newLabels.push(...Object.keys(change_log_daily));
      for (const date in change_log_daily) {
        const onCount = change_log_daily[date].on_count;
        const offCount = change_log_daily[date].off_count;

        newOnCount.push(onCount);
        newOffCount.push(offCount);
      }
    } else {
      const dailyData = aggregateDailyData(change_log, selectedKeyword);

      const sortedDates = Object.keys(dailyData).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
      newLabels.push(...sortedDates);
      for (const date in dailyData) {
        const onCount = dailyData[date].on_count;
        const offCount = dailyData[date].off_count;

        newOnCount.push(onCount);
        newOffCount.push(offCount);
      }
    }

    setLabels(newLabels);
    setOnCount(newOnCount);
    setOffCount(newOffCount);
  }, [selectedKeyword, change_log, change_log_daily]);

  const totalChangeLog = useMemo(
    () => ({
      on: on_count.reduce((acc: number, val: number) => {
        acc += val;
        return acc;
      }, 0),
      off: off_count.reduce((acc: number, val: number) => {
        acc += val;
        return acc;
      }, 0),
    }),
    [on_count, off_count]
  );

  const revenuePerCategoryData = {
    labels,
    datasets: [
      {
        label: 'Negative Removed',
        data: on_count,
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 0,
        borderRadius: 0,
        stack: 'stack0',
      },
      {
        label: 'Negative Applied',
        data: off_count,
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderWidth: 0,
        borderRadius: 0,
        stack: 'stack0',
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Daily Count',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        max: undefined,
      },
    },
    indexAxis: 'x',
  };

  // JSX
  return (
    <ChartContainer
      header="Change Log"
      description="Monitor the number of times a negative keyword within Auto Optimize
            was applied or removed"
      downloadParams={{
        filename: 'autoOptimizeChangeLog',
        headers: ['date', 'keyword', 'on', 'off'],
        labels: newXlsDates,
        columns: [newXlsKeywords, newXlsOnCounts, newXlsOffCounts],
      }}
    >
      <div className="flex flex-row items-start">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[80%] md:w-[80%] w-[80%] h-[295px]">
          <Bar data={revenuePerCategoryData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 ">
          <div className="flex items-center">
            <CustomLegend2 legendSet="set1" />
          </div>

          <div className="mt-12 text-sm font-bold flex flex-col items-start">
            <Dropdown
              title={`${selectedKeyword}`}
              options={availableKeywords}
              priorityOptions={['All Negative Keywords']}
              onOptionClick={setSelectedKeyword}
              dropdownLength="max-h-48"
            />
          </div>
        </div>
      </div>
      <div>
        <ChangeLogB data={totalChangeLog} />
      </div>
      <div>
        <ChangeLogTable data={change_log_table} />
      </div>
    </ChartContainer>
  );
}

export default ChangeLog;
