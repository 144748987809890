import 'chart.js/auto';
import { useState, useRef, useEffect, useMemo } from 'react';

// COMPONENTS
import Dropdown from '../../Dropdown';
import CustomLegend from './CustomLegend';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { Plugin, Chart, ArcElement, ChartTypeRegistry } from 'chart.js';

// UTILS
import { downloadImage, downloadXLS } from 'utils/download';

type LocationAnalysisData = {
  [device: string]: {
    [site: string]: {
      [location: string]: number;
    };
  };
};

interface BFLocationAnalysisProps {
  data: LocationAnalysisData;
  clientDomain: string;
}

interface Output {
  label: string[];
  columns: (string[] | number[])[];
}

const DEFAULT_DATA = {
  labels: ['No data', 'No data', 'No data', 'No data', 'No data'],
  datasets: [
    {
      data: [0, 0, 0, 0, 0],
      backgroundColor: [
        '#222222cc',
        '#909090cc',
        '#D9D9D9cc',
        '#32D76Fcc',
        '#03C5FFcc',
      ],
      hoverBackgroundColor: [
        '#222222cc',
        '#909090cc',
        '#D9D9D9cc',
        '#32D76Fcc',
        '#03C5FFcc',
      ],
      borderWidth: 1,
    },
  ],
};

const getDoughnutData = (
  device: 'desktop' | 'mobile',
  competitor: string,
  data: LocationAnalysisData
) => {
  const locationData: number[] = [];

  if (!data?.[device]?.[competitor]) {
    return DEFAULT_DATA;
  }

  Object.keys(data?.[device]?.[competitor]).forEach((location) => {
    if (location) {
      locationData.push(
        parseFloat(data?.[device]?.[competitor]?.[location].toFixed(2))
      );
    }
  });

  const labels: string[] = Object.keys(data?.[device]?.[competitor]).filter(
    (location) => !!location
  );
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: locationData,
        backgroundColor: [
          '#222222cc',
          '#909090cc',
          '#D9D9D9cc',
          '#32D76Fcc',
          '#03C5FFcc',
        ],
        hoverBackgroundColor: [
          '#222222cc',
          '#909090cc',
          '#D9D9D9cc',
          '#32D76Fcc',
          '#03C5FFcc',
        ],
        borderWidth: 1,
      },
    ],
  };

  return chartData;
};

interface DoughnutChartPlugin extends Plugin<'doughnut'> {}

const ChartDataLabelsPlugin = ChartDataLabels as unknown as DoughnutChartPlugin;

function BFLocationAnalysis({ data, clientDomain }: BFLocationAnalysisProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [desktopData, setDesktopData] = useState(DEFAULT_DATA);
  const [mobileData, setMobileData] = useState(DEFAULT_DATA);

  const competitorOptions = useMemo(() => {
    const combinedSet = new Set<string>();
    const desktopKeys = Object.keys(data?.desktop || {});
    const mobileKeys = Object.keys(data?.mobile || {});

    desktopKeys.forEach((key) => combinedSet.add(key));
    mobileKeys.forEach((key) => combinedSet.add(key));

    return Array.from(combinedSet);
  }, [data]);

  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(
    clientDomain ? clientDomain : competitorOptions[0]
  );

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : competitorOptions[0]);
  }, [data, clientDomain, competitorOptions]);

  useEffect(() => {
    setDesktopData(getDoughnutData('desktop', selectedCompetitor, data));
    setMobileData(getDoughnutData('mobile', selectedCompetitor, data));
  }, [data, selectedCompetitor]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      extendedGridLinePlugin: false,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'end' as const,
        color: '#1A1A1A',
        font: {
          weight: 'normal' as const,
          size: 12,
        },
        formatter: (value: number, context: Context) => {
          const dataset = context.dataset;
          const total = (dataset.data as number[]).reduce(
            (prevValue, currentValue) => prevValue + currentValue,
            0
          );
          if (total === 0) {
            return '0%';
          }
          const percentage = ((value / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
        padding: {
          top: 2,
          bottom: 2,
        },
        offset: 16,
      },
    },
    cutout: '50%',
    layout: {
      padding: {
        top: 35,
        bottom: 35,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const afterDraw = (chart: Chart<keyof ChartTypeRegistry>) => {
    const ctx = chart.ctx;
    const lineLength = 18.78; // Length of the lines

    if (chart.data.labels) {
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        chart.getDatasetMeta(datasetIndex).data.forEach((element) => {
          const arcElement = element as unknown as ArcElement;
          const radius = arcElement.outerRadius;

          // Calculate the angle for the line
          const angle =
            arcElement.startAngle +
            (arcElement.endAngle - arcElement.startAngle) / 2;
          const startX = chart.width / 2 + radius * Math.cos(angle);
          const startY = chart.height / 2 + radius * Math.sin(angle);
          const endX = startX + lineLength * Math.cos(angle);
          const endY = startY + lineLength * Math.sin(angle);

          // Draw the line from the outer edge of the doughnut
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = '#333';
          ctx.stroke();
        });
      });
    }
  };

  const afterDrawPlugin: Plugin<'doughnut'> = {
    id: 'afterDrawPlugin',
    afterDraw: (chart) => afterDraw(chart as Chart<keyof ChartTypeRegistry>),
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const DoughnutIcon = ({ color }: { color: string }) => (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ stroke: color }}
    >
      <path
        d="M6 6H10"
        strokeOpacity="0.8"
        strokeWidth="12"
        strokeLinecap="round"
      />
    </svg>
  );

  const desktopLegends = desktopData.labels.map((label, index) => ({
    label,
    color: desktopData.datasets[0].backgroundColor[index],
    icon: (
      <DoughnutIcon
        color={desktopData.datasets[0].backgroundColor[index % 5]}
      />
    ),
  }));

  const downloadChartImage = () => {
    downloadImage('LocationAnalysis');
  };

  const downloadXLSData = () => {
    const desktopLocationsData = data.desktop[selectedCompetitor];
    const mobileLocationsData = data.mobile[selectedCompetitor];
    const labels = Object.keys(desktopLocationsData);

    const desktopValues = labels.map(
      (location) => desktopLocationsData[location] || 0
    );
    const mobileValues = labels.map(
      (location) => mobileLocationsData[location] || 0
    );

    const output: Output = {
      label: labels,
      columns: [
        desktopValues.map((value) => value.toString()),
        mobileValues.map((value) => value.toString()),
      ],
    };

    downloadXLS(
      `LocationAnalysisDesktopAndMobile_${selectedCompetitor}`,
      ['Location', 'Desktop Coverage', 'Mobile Coverage'],
      output.label,
      output.columns
    );
  };

  return (
    <div
      id="LocationAnalysis"
      className="bg-light rounded-2xl shadow-sm mt-8 pb-6"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Location Analysis
            <div className="relative ml-1 mb-2 group inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className="px-4 py-2">
                  Chart will only show data for the implemented<br></br>
                  geolocations. Up to 5 geolocations allowed per study
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
                />
              </span>
            </div>
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            Understand your brand or chosen competitor&apos;s coverage by
            location
          </h3>
        </div>
        <div
          className="flex items-center mr-4"
          ref={dropdownRef}
          id="downloadDropdownMenu-LocationAnalysis"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadXLSData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[85%] w-[60%] h-[295px]">
          <div className="flex flex-col xl:flex-row items-start xl:gap-52">
            <div className="flex flex-col items-start">
              <h3 className="text-bold mb-2">Desktop Coverage</h3>
              <div className="relative w-80 h-60">
                <Doughnut
                  data={desktopData}
                  options={options}
                  plugins={[ChartDataLabelsPlugin, afterDrawPlugin]}
                />
              </div>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-bold mb-2">Mobile Coverage</h3>
              <div className="relative w-80 h-60">
                <Doughnut
                  data={mobileData}
                  options={options}
                  plugins={[ChartDataLabelsPlugin, afterDrawPlugin]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legends={desktopLegends} />
          <Dropdown
            title={`${selectedCompetitor}`}
            options={competitorOptions}
            onOptionClick={setSelectedCompetitor}
            dropdownLength="max-h-44"
          />
        </div>
      </div>
    </div>
  );
}

export default BFLocationAnalysis;
