interface PlatformsSetupProps {
  activeModules: boolean[];
  setActiveModules: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const PlatformsSetup: React.FC<PlatformsSetupProps> = ({
  activeModules,
  setActiveModules,
}) => {
  const moduleMap: { [key: number]: string } = {
    1: 'Insights',
    2: 'Competitive Intelligence',
    3: 'Consumer Intent',
    4: 'Auto Optimize',
  };

  const orderedKeys = [3, 2, 4, 1];

  const handleModuleClick = (index: number) => {
    const updatedModules = [...activeModules];
    updatedModules[index] = !updatedModules[index];
    setActiveModules(updatedModules);
  };

  return (
    <div>
      <h2 className="text-[32px] font-bold">Platforms Setup</h2>
      <h3 className="font-bold text-grayscale70 mt-2">
        Select and activate modules for customized functionality
      </h3>
      <div className="flex justify-between mt-6 space-x-2 mb-48">
        {orderedKeys.map((key) => (
          <div
            key={key}
            className={`w-[250px] h-[167px] flex justify-center items-center border rounded-2xl text-2xl font-medium relative text-center p-4 cursor-pointer ${
              activeModules[key - 1]
                ? 'border-success bg-disgreen50'
                : 'bg-white'
            }`} // Disable other modules
            onClick={() => handleModuleClick(key - 1)}
          >
            {moduleMap[key]}
            {activeModules[key - 1] && (
              <img
                src={`${process.env.PUBLIC_URL}/static/img/CheckmarkDone.svg`}
                alt="checkmarkdone"
                className="absolute top-2 right-2 flex items-center justify-center"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlatformsSetup;
