import { fetchCmpIntSearchGroups, fetchStudiesData } from '../api';
import { useQuery } from 'react-query';
import { APP_ROUTES } from '../constants';
import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// COMPONENTS
import Dropdown from './Dropdown';
import NavTabs from '../components/NavTabs';

// TYPES
import { CompIntSearchOptions } from 'types';

const TAB_OPTIONS = [
  { label: 'Overview', value: APP_ROUTES.COMP_INTEL_OVERVIEW },
  { label: 'Brand Focus', value: APP_ROUTES.COMP_INTEL_BRAND_FOCUS },
  { label: 'Content Analysis', value: APP_ROUTES.COMP_INTEL_CONTENT_ANALYSIS },
  { label: 'Reports', value: APP_ROUTES.COMP_INTEL_REPORTS },
];

const DEFAULT_SEARCH_OPTIONS: CompIntSearchOptions = {
  groups: [],
  terms: [],
  data: {},
};

// COMPETITIVE INTELLIGENCE PAGE (CONTAINER)
function Comp_IntelligencePage() {
  const { pathname } = useLocation();
  const [studyList, setStudyList] = useState<string[]>([]);
  const [currentStudy, setCurrentStudy] = useState<string>('');
  const [clientDomain, setClientDomain] = useState<string>('');

  const [selectedSearchOptions, setSelectedSearchOptions] =
    useState<CompIntSearchOptions>({ ...DEFAULT_SEARCH_OPTIONS });

  const { data: studyData, isLoading: isLoadingStudies } = useQuery(
    ['studyData'],
    () => fetchStudiesData()
  );

  const { data: searchOptions } = useQuery(
    ['searchTermGroups', currentStudy],
    () => fetchCmpIntSearchGroups()
  );

  useEffect(() => {
    if (!searchOptions) return;
    setSelectedSearchOptions({ ...searchOptions });
  }, [searchOptions]);

  useEffect(() => {
    if (studyData && !isLoadingStudies) {
      const { studyList, domain } = studyData;

      if (studyList) {
        setStudyList(studyList.map((item) => item.study_name));
        if (studyList.length > 0) {
          setCurrentStudy(studyList[0]?.study_name);
        }
      }

      if (domain) {
        setClientDomain(domain);
      }
    }
  }, [studyData, isLoadingStudies]);

  const onSelectSearchGroups = (selections: string[]) => {
    setSelectedSearchOptions((prev) => {
      const terms: string[] = [];

      for (const group of selections) {
        terms.push(...Object.values(prev.data[group] || []));
      }

      return {
        ...prev,
        terms,
        groups: [...selections],
      };
    });
  };

  const onSelectSearchTerms = (selections: string[]) => {
    setSelectedSearchOptions((prev) => ({ ...prev, terms: [...selections] }));
  };

  // JSX
  return (
    <NavTabs
      options={TAB_OPTIONS}
      customItems={
        <Dropdown
          title={`Study: ${currentStudy}`}
          options={studyList}
          dropdownWidth={'min-w-96'}
          dropdownOuterWidth={'max-w-96'}
          onOptionClick={setCurrentStudy}
        />
      }
    >
      {pathname !== APP_ROUTES.COMP_INTEL_REPORTS ? (
        <div className="flex items-center absolute top-20 z-20 right-0"></div>
      ) : null}
      <Outlet
        context={{
          clientDomain,
          searchOptions,
          selectedSearchOptions,
          onSelectSearchGroups,
          onSelectSearchTerms,
        }}
      />
    </NavTabs>
  );
}

export default Comp_IntelligencePage;
