import { useEffect, useState } from 'react';

// COMPONENTS
import FormModal from './FormModal';

// TYPES
import { GeoTargeting } from './types';

const FORM_ID = 'geolocation-modal-form';

interface GeolocationModalProps {
  data: GeoTargeting | null;
  customer_id?: string;
  onCancel: () => void;
  onSubmit: (updatedData: GeoTargeting) => void;
}

const DEFAULT_DATA: GeoTargeting = {
  customer_id: '',
  geo_id: 0,
  engine: '',
  device: '',
  language: '',
  language_code: '',
  country: '',
  country_code: '',
  state: '',
  city: '',
  postal_code: '',
  latitude: '',
  longitude: '',
  radius: '',
};

// COMP INTEL EDIT GEOLOCATION MODAL (SETTINGS)
function GeolocationModal({
  data,
  customer_id, // included for new geolocations
  onCancel,
  onSubmit,
}: GeolocationModalProps) {
  const [isError, setIsError] = useState(false);
  const [formState, setFormState] = useState({
    ...DEFAULT_DATA,
  });

  useEffect(() => {
    // Edit
    if (data) {
      setFormState({ ...data });
    }

    // New
    if (customer_id) {
      setFormState({ ...DEFAULT_DATA, customer_id });
    }
  }, [data, customer_id]);

  const onUpdate = (evt: React.FormEvent) => {
    evt.preventDefault();
    onSubmit(formState);
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  // JSX
  return (
    <FormModal
      header={`${data ? 'Edit' : 'Add'} Geo Targeting`}
      formId={FORM_ID}
      onCancel={onCancel}
      isError={isError}
    >
      <form
        className="grid grid-cols-2 gap-4"
        onSubmit={onUpdate}
        id={FORM_ID}
        onInvalid={() => {
          setIsError(true);
        }}
      >
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Engine*
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="engine"
            value={formState.engine || ''}
            onChange={onChange}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Device*
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="device"
            value={formState.device || ''}
            onChange={onChange}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Language*
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="language"
            value={formState.language || ''}
            onChange={onChange}
            required
          />
        </div>
        {customer_id ? (
          <div>
            <label className="block text-sm font-medium text-primarygray">
              Language Code*
            </label>
            <input
              type="text"
              className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
              name="language_code"
              value={formState.language_code}
              onChange={onChange}
              required
            />
          </div>
        ) : null}
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Country*
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="country"
            value={formState.country || ''}
            onChange={onChange}
            required
          />
        </div>
        {customer_id ? (
          <>
            <div>
              <label className="block text-sm font-medium text-primarygray">
                Country Code*
              </label>
              <input
                type="text"
                className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                name="country_code"
                value={formState.country_code}
                onChange={onChange}
                required
              />
            </div>
          </>
        ) : null}
        <div>
          <label className="block text-sm font-medium text-primarygray">
            State
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="state"
            value={formState.state || ''}
            onChange={onChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            City
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="city"
            value={formState.city || ''}
            onChange={onChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Postal Code
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="postal_code"
            value={formState.postal_code || ''}
            onChange={onChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Latitude
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="latitude"
            value={formState.latitude || ''}
            onChange={onChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Longitude
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="longitude"
            value={formState.longitude || ''}
            onChange={onChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-primarygray">
            Radius
          </label>
          <input
            type="text"
            className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
            name="radius"
            value={formState.radius || ''}
            onChange={onChange}
          />
        </div>
      </form>
    </FormModal>
  );
}

export default GeolocationModal;
