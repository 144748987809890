import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartOptions, ChartData as ChartJsData } from 'chart.js';
import CustomLegend2 from './CustomLegend2';
import { downloadXLS, downloadImage } from 'utils/download';

interface GrowingBrandProps {
  brands_growth_shrink_chart_data: {
    total_demand_per_period_brand: {
      period_formatted: string;
      Brand: string;
      total_demand: number;
    }[];
    max_demand_brand: {
      Brand: string;
    };
    min_demand_brand: {
      Brand: string;
    };
    max_demand_brand_real_terms: {
      Brand: string;
    };
  };
}

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)',
];

const GrowingBrand: React.FC<GrowingBrandProps> = ({
  brands_growth_shrink_chart_data,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLegendExpanded, setIsLegendExpanded] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const top5Brands =
    brands_growth_shrink_chart_data.total_demand_per_period_brand.slice(0, 5);
  const [visibility, setVisibility] = useState<Record<string, boolean>>(() => {
    return brands_growth_shrink_chart_data.total_demand_per_period_brand.reduce(
      (acc, item) => {
        const normalizedBrand = item.Brand.replace(/\s+/g, '');
        if (
          top5Brands.find((brand) => brand.Brand === item.Brand) &&
          normalizedBrand !== 'uncategorized'
        ) {
          acc[normalizedBrand] = true;
        } else {
          acc[normalizedBrand] = false;
        }
        return acc;
      },
      {} as Record<string, boolean>
    );
  });

  const handleLegendClick = (label: string) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label],
    }));
  };

  const brandsData =
    brands_growth_shrink_chart_data.total_demand_per_period_brand.reduce(
      (acc, item) => {
        const { period_formatted, Brand, total_demand } = item;
        if (!acc[Brand]) {
          const colorIndex = Object.keys(acc).length % colors.length;
          acc[Brand] = {
            label: Brand,
            color: colors[colorIndex],
            data: [],
          };
        }
        acc[Brand].data.push({ x: period_formatted, y: total_demand });
        return acc;
      },
      {} as Record<
        string,
        { label: string; color: string; data: { x: string; y: number }[] }
      >
    );

  const GrowData: ChartJsData<'line', { x: string; y: number }[], string> = {
    labels: Array.from(
      new Set(
        brands_growth_shrink_chart_data.total_demand_per_period_brand.map(
          (item) => item.period_formatted
        )
      )
    ),
    datasets: Object.values(brandsData).map((brand) => {
      const normalizedLabel = brand.label.replace(' ', '');

      return {
        label: brand.label,
        data: brand.data,
        borderColor: brand.color,
        borderWidth: 1.5,
        backgroundColor: brand.color,
        fill: false,
        pointRadius: 0,
        tension: 0.4,
        hidden: visibility[normalizedLabel] === false,
      };
    }),
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const visibleBrands = isLegendExpanded
    ? Object.values(brandsData)
    : Object.values(brandsData).slice(0, 8);

  const downloadChartData = () => {
    const headers = ['Month', ...Object.keys(brandsData)];
    const periods = GrowData.labels as string[];
    const columns = Object.values(brandsData).map((brand) =>
      brand.data.map((d) => d.y)
    );

    downloadXLS('Growing_Brand_Data', headers, periods, columns);
  };

  const downloadChartImage = () => {
    downloadImage('Growing_Brand_Chart');
  };

  return (
    <div
      id="Growing_Brand_Chart"
      className="bg-light rounded-2xl shadow-sm mt-8"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Which brands are growing and shrinking?
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            Uncover shifts in brand demand over time
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-Growing_Brand_Chart"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 xl:gap-0 xl:flex-row xl:justify-around mt-6">
        <div className="text-center">
          <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
            Fastest growing brand
            <div className="relative ml-1 mb-2 group inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className="px-4 py-2">
                  Brand with the hightest percentage of search demand
                  <br></br> growth over the past 3 months<br></br>
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180"
                />
              </span>
            </div>
          </h4>
          <p className="text-primarygray font-medium">
            {brands_growth_shrink_chart_data.max_demand_brand.Brand}
          </p>
        </div>
        <div className="text-center">
          <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
            Slowest growing brand
            <div className="relative ml-1 mb-2 group inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className="px-4 py-2">
                  Brand with the lowest percentage of search demand<br></br>{' '}
                  growth over the past 3 months<br></br>
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
                />
              </span>
            </div>
          </h4>
          <p className="text-primarygray font-medium">
            {brands_growth_shrink_chart_data.min_demand_brand.Brand}
          </p>
        </div>
        <div className="text-center">
          <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
            Fastest growing brand (real terms)
            <div className="relative ml-1 mb-2 group inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className="px-4 py-2">
                  Brand with the highest number of queries searched<br></br>
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180"
                />
              </span>
            </div>
          </h4>
          <p className="text-primarygray font-medium">
            {brands_growth_shrink_chart_data.max_demand_brand_real_terms.Brand}
          </p>
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={GrowData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegend2
            brandsData={visibleBrands}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
          <button
            onClick={() => setIsLegendExpanded(!isLegendExpanded)}
            className="mt-2 bg-light hover:underline"
          >
            {isLegendExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GrowingBrand;
