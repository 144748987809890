import { useState, useEffect, useRef, useMemo } from 'react';

// UTILS
import { sortFuncDropdownOptions } from 'utils';

export type DropdownOption<V extends string | number = string> = {
  label: string;
  value: V;
};

interface DropdownProps<T extends DropdownOption | string> {
  title: string;
  options: T[];
  onOptionClick: (option: T) => void;
  dropdownLength?: string;
  dropdownOuterWidth?: string;
  dropdownWidth?: string;
  priorityOptions?: T[];
  mode?: string;
  className?: string;
  position?: string;
  positionY?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
}

function Dropdown<T extends DropdownOption | string = string>({
  title,
  options = [],
  onOptionClick,
  dropdownLength = 'max-h-32',
  dropdownOuterWidth = 'max-w-40',
  dropdownWidth = 'min-w-48 max-w-72',
  priorityOptions = [],
  mode = 'light',
  className = '',
  position = 'right-0',
  positionY = 'top-10',
  disabled = false,
  value,
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const toggleDropdown = () => {
    if (disabled) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: T) => {
    onOptionClick(option);
    setIsOpen(false);
    setSearchTerm('');
  };

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = useMemo(
    () =>
      options?.filter((option) => {
        if (typeof option === 'string') {
          return option.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return option.label.toLowerCase().includes(searchTerm.toLowerCase());
        }
      }) || [],
    [options, searchTerm]
  );

  const sortedOptions = useMemo(
    () => [
      ...priorityOptions,
      ...filteredOptions
        .filter((option) => !priorityOptions.includes(option))
        .sort(sortFuncDropdownOptions),
    ],
    [filteredOptions, priorityOptions]
  );

  // Hook that closes compnent if click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className={`${className} text-base relative ${
        disabled ? 'hover:cursor-not-allowed' : ''
      } ${(() => {
        if (mode === 'dark') {
          return 'bg-primarygray text-white';
        } else if (mode === 'light') {
          return `${
            disabled ? 'bg-gray-200' : 'bg-white hover:bg-gray-100'
          } border border-gray300 text-thirdgray`;
        }
      })()}
        py-1.5 pl-4 pr-4 rounded-lg font-medium cursor-pointer whitespace-nowrap`}
      onClick={toggleDropdown}
    >
      <div className="flex justify-between items-center gap-4 text-center">
        <span className={`${dropdownOuterWidth} truncate`} title={title}>
          {title}
        </span>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
          className={`h-3 w-3 my-1 ml-1 transform ${
            isOpen ? '-rotate-90' : 'rotate-90'
          }`}
          alt="Arrow"
        />
      </div>
      {isOpen && (
        <div
          className={`absolute z-10 ${(() => {
            if (mode === 'dark') {
              return 'bg-primarygray text-white border-white divide-white';
            } else if (mode === 'light') {
              return 'bg-white border-gray300 divide-gray300';
            }
          })()}
        shadow-md border divide-y rounded-lg ${position} ${positionY} text-center ${dropdownLength} ${dropdownWidth} overflow-y-auto`}
        >
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            type="text"
            className={
              mode === 'dark'
                ? `w-full px-4 py-2 bg-primarygray border-b border-gray-300 focus:outline-none`
                : `w-full px-4 py-2 border-b border-gray-300 focus:outline-none`
            }
            placeholder={'Search...'}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {sortedOptions.map((option, index) => (
            <div
              key={index}
              className={`py-1 px-6 cursor-pointer ${
                selected === option && mode === 'dark'
                  ? 'bg-gray-800 '
                  : selected === option && mode === 'light'
                  ? 'bg-gray-200 '
                  : ''
              }
              ${(() => {
                if (mode === 'dark') {
                  return 'hover:bg-gray-800';
                } else if (mode === 'light') {
                  return 'hover:bg-gray-200';
                }
              })()}`}
              onClick={() => handleOptionClick(option)}
            >
              {typeof option === 'string' ? option : option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
