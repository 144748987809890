import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';
import { useEffect, useMemo, useState } from 'react';

// API REQUESTS
import { fetchIntentQueryViewData } from 'api';

// COMPONENTS
import MultiDropdown from '../MultiDropdown';
import DataTable, { ColumnSort, TableColumns } from '../DataTable';

// TYPES
import { QueryViewData, QueryViewTableFilters } from 'types';

const DEFAULT_OPTIONS: QueryViewTableFilters = {
  queries: null,
  brand: null,
  mindset: null,
  feature_group: null,
};
const DEFAULT_FILTERS: QueryViewTableFilters = {
  queries: null,
  brand: null,
  mindset: null,
  feature_group: null,
};

const columns: TableColumns<QueryViewData>[] = [
  { header: 'Query', bind: 'Queries' },
  { header: 'Mindset', bind: 'Mindset' },
  { header: 'Vertical', bind: 'Vertical' },
  { header: 'Brand', bind: 'Brand' },
  { header: 'Product', bind: 'Product' },
  { header: 'Feature Group', bind: 'Feature_Group' },
  { header: 'Feature', bind: 'Feature' },
  { header: 'Retailer', bind: 'Retailer' },
  { header: 'Demand', bind: 'Demand' },
];

const DEFAULT_SORT: ColumnSort<QueryViewData> = {
  sort_column: 'Demand',
  sort_direction: 'desc',
};

// CONSUMER INTENT QUERY VIEW TAB
function QueryView() {
  const { dateRange, selectedProducts, selectedVerticals } = useIntentContext();

  const [filterValues, setFilterValues] = useState({ ...DEFAULT_FILTERS });
  const [filterOptions, setFilterOptions] =
    useState<QueryViewTableFilters | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [columnSort, setColumnSort] = useState<ColumnSort<QueryViewData>>({
    ...DEFAULT_SORT,
  });

  const dataFilters = useMemo(
    () => ({
      ...filterValues,
      verticals: [...selectedVerticals],
      products: [...selectedProducts],
    }),
    [filterValues, selectedProducts, selectedVerticals]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange]);

  const { data, isLoading } = useQuery(
    [
      'consumerIntentQueryView',
      dateRange,
      dataFilters,
      currentPage,
      columnSort,
    ],
    () =>
      fetchIntentQueryViewData(dateRange!, dataFilters, {
        pagination: { page: currentPage },
        columnSort,
      }),
    {
      enabled: !!dateRange?.startDate && !!dateRange.endDate,
      staleTime: 1000 * 60 * 60,
    }
  );

  useEffect(() => {
    if (!data || filterOptions) return;
    const options = { ...DEFAULT_OPTIONS };

    if (data) {
      options.brand = data.all_filters.all_brands;
      options.feature_group = data.all_filters.all_feature_groups;
      options.mindset = data.all_filters.all_mindsets;
      options.queries = data.all_filters.all_queries;
    }
    setFilterOptions(options);
  }, [data, filterOptions]);

  const onClearFilters = () => {
    setFilterValues({ ...DEFAULT_FILTERS });
    setCurrentPage(1);
  };

  const onApplyFilters = (
    selections: string[],
    key: keyof QueryViewTableFilters
  ) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: selections.length ? [...selections] : null,
    }));
    setCurrentPage(1);
  };

  const onSortColumn = (sortInfo: ColumnSort<QueryViewData>) => {
    console.log(sortInfo);
    setColumnSort({ ...sortInfo });
  };

  // JSX
  return (
    <div className="pt-4">
      <DataTable
        above={
          <div>
            <div className="flex justify-end mb-2 -translate-y-2">
              <button
                className="bg-thirdgray text-xs hover:bg-[#848484] active:bg-[#646464] text-white py-1 px-2 rounded"
                onClick={onClearFilters}
              >
                Clear Filters
              </button>
            </div>
            <div className="flex justify-between">
              <MultiDropdown
                placeholder={'Queries'}
                options={filterOptions?.queries || []}
                preselectedOptions={filterValues?.queries || []}
                onSelectionChange={(selections) => {
                  onApplyFilters(selections, 'queries');
                }}
                dropdownLength="max-h-60"
                alignMenu="start"
                className="max-w-48"
              />
              <MultiDropdown
                placeholder={'Brand'}
                options={filterOptions?.brand || []}
                preselectedOptions={filterValues.brand || []}
                onSelectionChange={(selections) => {
                  onApplyFilters(selections, 'brand');
                }}
                dropdownLength="max-h-60"
                alignMenu="center"
                className="max-w-48"
              />
              <MultiDropdown
                placeholder={'Feature Group'}
                options={filterOptions?.feature_group || []}
                preselectedOptions={filterValues.feature_group || []}
                onSelectionChange={(selections) => {
                  onApplyFilters(selections, 'feature_group');
                }}
                dropdownLength="max-h-60"
                alignMenu="center"
                className="max-w-48"
              />
              <MultiDropdown
                placeholder={'Mindset'}
                options={filterOptions?.mindset || []}
                preselectedOptions={filterValues.mindset || []}
                onSelectionChange={(selections) => {
                  onApplyFilters(selections, 'mindset');
                }}
                dropdownLength="max-h-60"
                className="max-w-48"
              />
            </div>
          </div>
        }
        isLoading={isLoading}
        data={data?.filtered_data || []}
        columns={columns}
        currentPage={data?.page}
        itemsPerPage={data?.page_size || 50}
        totalItems={data?.total_records}
        onPageChange={setCurrentPage}
        onSort={onSortColumn}
      />
    </div>
  );
}

export default QueryView;
