import { useMemo, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// API REQUESTS
import { getOnboardingBaseData } from 'api';

// COMPONENTS
import LoadingSpinner from '../LoadingSpinner';
import GoogleEmailDropdown from '../Onboarding_comp/GoogleEmailDropdown';

interface Account {
  customer_id?: string[] | string;
  login_customer_id?: string;
  properties?: string[];
  property_id?: string;
  account_id?: string;
}

interface Api {
  name: string;
  id: string;
  logo: string;
  accounts: Account[];
}

interface APIsCfgProps {
  customerId: string;
  googleEmail: string;
  setGoogleEmail: React.Dispatch<React.SetStateAction<string>>;
}

interface CustomerInfo {
  customer_id: string;
}

interface AccountConfiguration {
  google_search_ads?: Account;
  google_ads?: Account;
  google_search_console?: Account;
  google_analytics?: Account;
  microsoft_ads?: Account;
}

interface CustomerData {
  customer_info: CustomerInfo;
  account_configurations: AccountConfiguration[];
  google_email_account: string;
}

interface ApiResponse {
  customers: CustomerData[];
}

const initialAPIs: Api[] = [
  {
    name: 'Google Search Ads 360',
    id: 'google-search-ads-360',
    logo: `${process.env.PUBLIC_URL}/static/img/search_ads.png`,
    accounts: [{ customer_id: [''], login_customer_id: '' }],
  },
  {
    name: 'Google Ads',
    id: 'google-ads',
    logo: `${process.env.PUBLIC_URL}/static/img/google_ads.png`,
    accounts: [{ customer_id: [''], login_customer_id: '' }],
  },
  {
    name: 'Google Search Console',
    id: 'google-search-console',
    logo: `${process.env.PUBLIC_URL}/static/img/search_console.png`,
    accounts: [{ properties: [''] }],
  },
  {
    name: 'Google Analytics',
    id: 'google-analytics',
    logo: `${process.env.PUBLIC_URL}/static/img/analytics.png`,
    accounts: [{ property_id: '' }],
  },
  {
    name: 'Microsoft Ads',
    id: 'microsoft-ads',
    logo: `${process.env.PUBLIC_URL}/static/img/microsoft_ads.png`,
    accounts: [{ customer_id: '', account_id: '' }],
  },
];

const isGoogleEmailRequired = (apiData: Api[]) => {
  for (const { accounts } of apiData) {
    for (const account of accounts) {
      if (
        account.account_id ||
        account.property_id ||
        account.login_customer_id
      ) {
        return true;
      }

      for (const id of account.customer_id || []) {
        if (id) return true;
      }

      for (const property of account.properties || []) {
        if (property) return true;
      }
    }
  }
  return false;
};

function APIsCfg({ customerId, googleEmail, setGoogleEmail }: APIsCfgProps) {
  const queryClient = useQueryClient();
  const [apis, setApis] = useState<Api[]>(initialAPIs);
  const [hasChanges, setHasChanges] = useState(false);
  const [changedApis, setChangedApis] = useState<Set<string>>(new Set());

  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  const { data: onboardingData } = useQuery(['onboardingBaseData'], {
    queryFn: getOnboardingBaseData,
  });

  const googleEmailOptions = onboardingData?.google_email_accounts || [];

  const onSetGoogleEmail = (selected: string) => {
    setGoogleEmail((prev) => (selected === prev ? '' : selected));
    setHasChanges(true);
  };

  // Google Console email requirement check
  const isEmailRequired = useMemo(() => isGoogleEmailRequired(apis), [apis]);

  const { isLoading, error } = useQuery(
    ['customerInfo', customerId],
    async () => {
      const response = await fetch('/customer-info-onboard/');

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data: ApiResponse = await response.json();
      return data;
    },
    {
      onSuccess: (data) => {
        const customerData = data.customers.find(
          (customer) => customer.customer_info.customer_id === customerId
        );

        if (customerData) {
          const accountConfig = customerData.account_configurations[0];
          const updatedApis = initialAPIs.map((api) => {
            switch (api.id) {
              case 'google-search-ads-360':
                return accountConfig.google_search_ads
                  ? { ...api, accounts: [accountConfig.google_search_ads] }
                  : api;
              case 'google-ads':
                return accountConfig.google_ads
                  ? { ...api, accounts: [accountConfig.google_ads] }
                  : api;
              case 'google-search-console':
                return accountConfig.google_search_console
                  ? { ...api, accounts: [accountConfig.google_search_console] }
                  : api;
              case 'google-analytics':
                return accountConfig.google_analytics
                  ? { ...api, accounts: [accountConfig.google_analytics] }
                  : api;
              case 'microsoft-ads':
                return accountConfig.microsoft_ads
                  ? { ...api, accounts: [accountConfig.microsoft_ads] }
                  : api;
              default:
                return api;
            }
          });

          setApis(updatedApis);

          if (customerData.google_email_account) {
            setIsEmailDisabled(true);
            setGoogleEmail(customerData.google_email_account);
          }
        }
      },
    }
  );

  const mutation = useMutation(
    async (updatedApis: Api[]) => {
      // Only include the changed APIs in the update payload
      const updatedData: Partial<AccountConfiguration> = {};

      changedApis.forEach((apiId) => {
        const api = updatedApis.find((api) => api.id === apiId);
        if (api && api.accounts[0]) {
          switch (api.id) {
            case 'google-search-ads-360':
              updatedData.google_search_ads = api.accounts[0];
              break;
            case 'google-ads':
              updatedData.google_ads = api.accounts[0];
              break;
            case 'google-search-console':
              updatedData.google_search_console = api.accounts[0];
              break;
            case 'google-analytics':
              updatedData.google_analytics = api.accounts[0];
              break;
            case 'microsoft-ads':
              updatedData.microsoft_ads = api.accounts[0];
              break;
            default:
              break;
          }
        }
      });

      const response = await fetch(`/customer-apicfg-update/${customerId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account_configurations: updatedData,
          google_email_account: googleEmail,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customerInfo', customerId]);
        setHasChanges(false); // Reset changes after successful save
        setChangedApis(new Set()); // Clear changed APIs after successful save
      },
    }
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    apiIndex: number,
    accountIndex: number,
    itemIndex?: number
  ) => {
    const { name, value } = e.target;
    const newApis = [...apis];
    const key = name as keyof Account;

    if (
      key === 'customer_id' &&
      Array.isArray(newApis[apiIndex].accounts[accountIndex].customer_id) &&
      itemIndex !== undefined
    ) {
      const updatedCustomerIds = [
        ...(newApis[apiIndex].accounts[accountIndex].customer_id as string[]),
      ];
      updatedCustomerIds[itemIndex] = value;
      newApis[apiIndex].accounts[accountIndex].customer_id = updatedCustomerIds;
    } else if (
      key === 'properties' &&
      Array.isArray(newApis[apiIndex].accounts[accountIndex].properties) &&
      itemIndex !== undefined
    ) {
      const updatedProperties = [
        ...(newApis[apiIndex].accounts[accountIndex].properties as string[]),
      ];
      updatedProperties[itemIndex] = value;
      newApis[apiIndex].accounts[accountIndex].properties = updatedProperties;
    } else {
      (newApis[apiIndex].accounts[accountIndex][key] as string) = value;
    }

    setApis(newApis);
    setHasChanges(true); // Mark as unsaved changes
    setChangedApis((prev) => new Set(prev).add(newApis[apiIndex].id)); // Track the changed API
  };

  const handleSave = () => {
    const userConfirmed = window.confirm(
      'Are you sure you want to save changes?'
    );

    if (userConfirmed) {
      mutation.mutate(apis, {
        onSuccess: () => {
          setHasChanges(false);
          setChangedApis(new Set());
        },
      });
    } else {
      setHasChanges(false);
    }
  };

  const handleAddMore = (index: number) => {
    const newApis = [...apis];
    const account = newApis[index].accounts[0];

    if ('customer_id' in account) {
      if (
        Array.isArray(account.customer_id) &&
        account.customer_id.length < 3
      ) {
        account.customer_id.push('');
      }
    } else if ('properties' in account) {
      if (Array.isArray(account.properties) && account.properties.length < 3) {
        account.properties.push('');
      }
    }

    setApis(newApis); // Mark as unsaved changes
    setHasChanges(true);
    setChangedApis((prev) => new Set(prev).add(newApis[index].id)); // Track the changed API
  };

  const handleRemove = (
    apiIndex: number,
    accountIndex: number,
    itemIndex?: number
  ) => {
    const newApis = [...apis];
    const account = newApis[apiIndex].accounts[accountIndex];

    if ('customer_id' in account && Array.isArray(account.customer_id)) {
      if (account.customer_id.length > 1 && typeof itemIndex === 'number') {
        account.customer_id.splice(itemIndex, 1);
      }
    } else if ('properties' in account && Array.isArray(account.properties)) {
      if (account.properties.length > 1 && typeof itemIndex === 'number') {
        account.properties.splice(itemIndex, 1);
      }
    }

    setApis(newApis);
    setHasChanges(true); // Mark as unsaved changes
    setChangedApis((prev) => new Set(prev).add(newApis[apiIndex].id)); // Track the changed API
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Failed to fetch data</div>;
  }

  return (
    <div className="border-y border-success py-6">
      {/* Save Button */}
      <div className="flex justify-end mb-4">
        <button
          className={`px-4 py-2 rounded-full ${
            hasChanges ? 'bg-success' : 'bg-gray200'
          } text-light`}
          onClick={handleSave}
          disabled={!hasChanges} // Disable the button if no changes
        >
          Save
        </button>
      </div>

      <div className="space-y-8">
        <GoogleEmailDropdown
          options={googleEmailOptions}
          value={googleEmail}
          onChange={onSetGoogleEmail}
          isDisabled={isEmailDisabled}
          isRequired={isEmailRequired}
        />
        {apis.map((api, index) => (
          <div key={api.id} className="space-y-2">
            <div className="flex items-center space-x-2">
              <img
                src={api.logo}
                alt={`${api.name} logo`}
                className="w-6 h-6"
              />
              <span className="text-primarygray font-bold">{api.name}</span>
              {api.id !== 'microsoft-ads' && api.id !== 'google-analytics' && (
                <div className="border border-thirdgray rounded-[20px] px-2">
                  <button
                    onClick={() => handleAddMore(index)}
                    className="text-sm text-thirdgray font-bold flex items-center"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
                      alt="plus_icon"
                      className="w-[18px] h-[18px] mr-1"
                    />
                    ADD MORE
                  </button>
                </div>
              )}
            </div>
            {api.accounts.map((account, accountIndex) => (
              <div
                key={accountIndex}
                className="relative flex flex-col space-y-4"
              >
                {accountIndex > 0 && (
                  <div className="absolute -top-2 right-0">
                    <button
                      onClick={() => handleRemove(index, accountIndex)}
                      className="text-error"
                    >
                      &times;
                    </button>
                  </div>
                )}
                <div className="flex items-center space-x-6">
                  {('customer_id' in account ||
                    'login_customer_id' in account) && (
                    <>
                      <div className="flex items-center space-x-2 flex-grow">
                        <label className="text-sm font-bold">Customer ID</label>
                        <div className="flex flex-col space-y-2 flex-grow">
                          {Array.isArray(account.customer_id) ? (
                            account.customer_id.map(
                              (id: string, customerIndex: number) => (
                                <div
                                  key={customerIndex}
                                  className="flex items-center space-x-2"
                                >
                                  <input
                                    type="text"
                                    placeholder="Type here"
                                    name="customer_id"
                                    value={id}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        index,
                                        accountIndex,
                                        customerIndex
                                      )
                                    }
                                    className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                                  />
                                  {customerIndex > 0 && (
                                    <button
                                      onClick={() =>
                                        handleRemove(
                                          index,
                                          accountIndex,
                                          customerIndex
                                        )
                                      }
                                      className="text-error"
                                    >
                                      &times;
                                    </button>
                                  )}
                                </div>
                              )
                            )
                          ) : (
                            <input
                              type="text"
                              placeholder="Type here"
                              name="customer_id"
                              value={account.customer_id as string}
                              onChange={(e) =>
                                handleChange(e, index, accountIndex)
                              }
                              className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                            />
                          )}
                        </div>
                      </div>
                      {api.id !== 'microsoft-ads' && (
                        <div className="flex items-center space-x-2 flex-grow">
                          <label className="text-sm font-bold">
                            Login Customer ID
                          </label>
                          <input
                            type="text"
                            placeholder="Type here"
                            name="login_customer_id"
                            value={account.login_customer_id || ''}
                            onChange={(e) =>
                              handleChange(e, index, accountIndex)
                            }
                            className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                          />
                        </div>
                      )}
                    </>
                  )}
                  {'properties' in account &&
                    Array.isArray(account.properties) && (
                      <div className="flex items-center space-x-2 flex-grow">
                        <label className="text-sm font-bold">Properties</label>
                        <div className="flex flex-col space-y-2 flex-grow">
                          {account.properties.map((property, propertyIndex) => (
                            <div
                              key={propertyIndex}
                              className="flex items-center space-x-2"
                            >
                              <input
                                type="text"
                                placeholder="Type here"
                                name="properties"
                                value={property}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    index,
                                    accountIndex,
                                    propertyIndex
                                  )
                                }
                                className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                              />
                              {propertyIndex > 0 && (
                                <button
                                  onClick={() =>
                                    handleRemove(
                                      index,
                                      accountIndex,
                                      propertyIndex
                                    )
                                  }
                                  className="text-error"
                                >
                                  &times;
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  {'property_id' in account && (
                    <div className="flex items-center space-x-2 flex-grow">
                      <label className="text-sm font-bold">Property ID</label>
                      <input
                        type="text"
                        placeholder="Type here"
                        name="property_id"
                        value={account.property_id || ''}
                        onChange={(e) => handleChange(e, index, accountIndex)}
                        className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                      />
                    </div>
                  )}
                  {'account_id' in account && (
                    <div className="flex items-center space-x-2 flex-grow">
                      <label className="text-sm font-bold">Account ID</label>
                      <input
                        type="text"
                        placeholder="Type here"
                        name="account_id"
                        value={account.account_id || ''}
                        onChange={(e) => handleChange(e, index, accountIndex)}
                        className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default APIsCfg;
