import axios from 'axios';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

interface AddClientModalProps {
  agencies: Agency[];
  defaultAgency?: string;
  onCancel: () => void;
}

const addNewClient = async (agency_id: string, client_name: string) => {
  const { data } = await axios.post('add-new-client/', {
    agency_id,
    client_name,
  });

  return data;
};

function AddClientModal({
  agencies,
  defaultAgency = '',
  onCancel,
}: AddClientModalProps) {
  const queryClient = useQueryClient();
  const [state, setState] = useState({
    agency: defaultAgency,
    client: '',
  });

  console.log(defaultAgency);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const onSubmitForm = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!state.agency || !state.client) {
      setErrors((prev) => ({
        ...prev,
        request: 'Missing required field values.',
      }));
      return;
    }

    addNewClient(state.agency, state.client)
      .then(() => {
        queryClient.invalidateQueries('base_setup_data');
        onCancel();
      })
      .catch((err) => {
        console.log('got here....');
        let errorMsg = 'Unable to add client.';
        if (axios.isAxiosError(err)) {
          errorMsg = err.response?.data.error;
        }
        setErrors((prev) => ({ ...prev, request: errorMsg }));
      });
  };

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = evt.currentTarget;

    if (value && errors[name]) {
      setErrors((prev) => {
        const copy = { ...prev };
        delete copy[name];
        return copy;
      });
    }

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onBlurValidation = (
    evt: React.FocusEvent<HTMLSelectElement | HTMLInputElement, Element>
  ) => {
    const { name, value } = evt.currentTarget;

    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: `${
          name[0].toUpperCase() + name.slice(1).toLowerCase()
        } is required.`,
      }));
    }
  };

  // JSX
  return (
    <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="absolute flex flex-col gap-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded min-w-96 min-h-80">
        <h2 className="font-bold text-2xl">Add New Client:</h2>
        {errors.request ? (
          <div className="text-error text-center text-sm">{errors.request}</div>
        ) : null}
        <form
          onSubmit={onSubmitForm}
          className="flex-1 flex flex-col justify-between gap-6"
        >
          <div>
            <label className="block font-bold text-thirdgray text-sm mb-2">
              Agency:*
            </label>
            <select
              className="w-full border border-gray200 rounded p-2 text-gray600 text-sm disabled:cursor-not-allowed"
              name="agency"
              onBlur={onBlurValidation}
              onChange={onChange}
              value={state.agency}
              disabled={!!defaultAgency}
            >
              <option value="">Select</option>
              {agencies
                .slice()
                .sort((a, b) =>
                  a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                )
                .map((agency) => (
                  <option key={agency.id} value={agency.id}>
                    {agency.name}
                  </option>
                ))}
            </select>
            {errors.agency ? (
              <span className="text-error text-xs">{errors.agency}</span>
            ) : null}
          </div>

          <div>
            <label className="block font-bold text-thirdgray text-sm mb-2">
              Client Name:*
            </label>
            <input
              className="w-full border border-gray200 rounded p-2 text-gray600 text-sm bg-white"
              name="client"
              onBlur={onBlurValidation}
              onChange={onChange}
              value={state.client}
              required
            />
            {errors.client ? (
              <span className="text-error text-xs">{errors.client}</span>
            ) : null}
          </div>
          <div className="flex gap-4 justify-end">
            <button
              className="text-sm font-bold py-1 px-4 bg-thirdgray text-light rounded-2xl"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="text-sm font-bold py-1 px-4 bg-success text-light rounded-2xl"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddClientModal;
