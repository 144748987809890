import {
  SearchGroup,
  GeoTargeting,
  Configuration,
  CompetitorGroup,
  CompetitiveIntelligenceData,
} from './types';

export const fetchCompetitiveIntelligenceData = async (customerId: string) => {
  const response = await fetch('/competitive-intelligence-list/');
  const data: CompetitiveIntelligenceData[] = await response.json();
  const filteredData = data.find(
    (d) => d.customer_info.customer_id === customerId
  );
  return filteredData || null;
};

export const updateConfiguration = async (config: Configuration) => {
  try {
    const response = await fetch(
      `edit-configuration-comp-int/${config.study_id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(config),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Update configuration failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

export const deleteGeoTargeting = async (geoId: number) => {
  const response = await fetch(`/delete-item/geo-targeting/${geoId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export const deleteSearchGroup = async (groupId: number) => {
  const response = await fetch(`/delete-item/search-group/${groupId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export const deleteCompetitorGroup = async (groupId: number) => {
  const response = await fetch(`/delete-item/competitor-group/${groupId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export const uploadFile = async ({
  CompIntfileCFG,
  customerId,
  studyName,
  domain,
  frequency,
  provider,
}: {
  CompIntfileCFG: File;
  customerId: string;
  studyName: string;
  domain: string;
  frequency: number;
  provider: string;
}) => {
  const formData = new FormData();
  formData.append('file', CompIntfileCFG);
  formData.append('product', 'comp_int');

  if (customerId) {
    formData.append('customer_id', customerId);
  }
  formData.append('study_name', studyName);
  formData.append('domain', domain);
  formData.append('frequency', frequency.toString());
  formData.append('provider', provider);

  try {
    const response = await fetch('/upload/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`File upload failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

export const createGeoTargeting = async (geoTargeting: GeoTargeting) => {
  const response = await fetch('/create-geo-targeting/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(geoTargeting),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Create Geo Targeting failed: ${errorText}`);
  }

  return response.json();
};

export const createSearchGroup = async (searchGroup: SearchGroup) => {
  const response = await fetch('/create-search-group/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(searchGroup),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Create Search Group failed: ${errorText}`);
  }

  return response.json();
};

export const createCompetitorGroup = async (
  competitorGroup: CompetitorGroup
) => {
  const response = await fetch('/create-competitor-group/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(competitorGroup),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Create Competitor Group failed: ${errorText}`);
  }

  return response.json();
};
